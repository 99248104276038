import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function RTextEditor({ value, placeholder, onChange }: { value: string, placeholder?: string, onChange: (val: string) => void }) {

    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "super" }, { script: "sub" }],
            [{ header: 1 }, { header: 2 }],
            [{ header: [3, 4, 5, 6] }, "blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["direction", { align: [] }],
            ["link", "image"],
            ["clean"]
        ]
    };

    const formats = [
        "bold", "italic", "underline", "strike", "blockquote",
        "header", "font", "size",
        "color", "background",
        "script", "code-block",
        "list", "bullet", "indent",
        "direction", "align",
        "link", "image"
    ];

    return (
        <ReactQuill
            theme={'snow'}
            modules={modules}
            formats={formats}
            value={value}
            onChange={(e) => onChange(e)}
            placeholder={placeholder}
        // className={}
        />
    );
}