import { useAuth } from "../../Providers/AuthContext"
import i18next from "i18next"
import { LuCalendarCheck2, LuCalendarClock, LuCalendarRange, LuUserPlus, LuUsers2 } from "react-icons/lu";
import { useModal } from "../../hooks/modal/use-modal";
import CreateUser from "../../views/CreateUser";
import CreateEvent from "../../views/CreateEvent";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layouts/Layout";
import CreatePracticalUse from "../../views/CreatePracticalUse";

export default function Dashboard() {
    const { user } = useAuth();
    const { openModal } = useModal();
    const navigate = useNavigate();

    const memberActions = [
        {
            name: i18next.t("label.plan_event"),
            description:
                'Plane einen neuen Termin, eine Besprechung oder ein anderes wichtiges Event, um sicherzustellen, dass alle Beteiligten informiert sind.',
            icon: LuCalendarClock,
            action: () => { openModal({ view: <CreateEvent /> }) }
        },
        // {
        //     name: i18next.t("label.plan_seminar"),
        //     description:
        //         'Plane ein neues Seminar und informiere alle Beteiligten rechtzeitig, um eine hohe Teilnahme und eine effektive Vorbereitung zu gewährleisten.',
        //     icon: LuCalendarRange,
        //     action: () => { }

        // },
        {
            name: i18next.t("label.check_practice_spaces"),
            description:
                'Überprüfe die derzeit angegebenen Plätze für Praxiseinsätze, um sicherzustellen, dass sie den Anforderungen und Erwartungen entsprechen und allen Beteiligten eine wertvolle Erfahrung bieten.',
            icon: LuUsers2,
            action: () => { navigate("/app/admin/practical_applications") }

        },
        {
            name: i18next.t("label.add_user"),
            description:
                'Füge einen neuen Benutzer zum System hinzu und stelle sicher, dass er alle notwendigen Berechtigungen und Zugriffsrechte erhält, um seine Aufgaben effizient zu erfüllen.',
            icon: LuUserPlus,
            action: () => { openModal({ view: <CreateUser /> }) }

        },
    ]
    const partnerActions = [
        {
            name: i18next.t("label.plan_practical_use"),
            description:
                'Teile neue Termine für einen Praxiseinsatz mit und stelle sicher, dass alle Teilnehmenden rechtzeitig informiert werden, um eine reibungslose Durchführung und optimale Beteiligung zu gewährleisten.',
            icon: LuCalendarCheck2,
            action: () => { openModal({ view: <CreatePracticalUse /> }) }

        }
    ]

    const getActions = () => {
        const role = user?.role;
        if (role === 'admin') {
            // Admin bekommt beide Listen
            return [...memberActions, ...partnerActions];
        } else if (role === 'member') {
            return memberActions;
        } else if (role === 'partner') {
            return partnerActions;
        }

        // Falls keine Rolle zutrifft, eine leere Liste oder eine Fehlermeldung zurückgeben
        return [];
    }

    const userActions = getActions();


    return (
        <Layout>
            <div className="bg-white ">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl">
                            {i18next.t("label.hello")} {user?.firstname}
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {i18next.t("text.choose_whats_next")}
                        </p>
                    </div>
                    <div className="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-4xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            {userActions.map((feature) => (
                                <div className="rounded-lg bg-white shadow hover:bg-gray-50 p-2 cursor-pointer" key={Math.random()} onClick={feature.action}>
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="relative pl-16">
                                            <dt className="text-base font-semibold leading-7 text-gray-700">
                                                <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-teal-600">
                                                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </div>
                                                {feature.name}
                                            </dt>
                                            <dd className="mt-2 text-sm leading-7 text-gray-600">{feature.description}</dd>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
