/* 
File: RModal
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\components
Created at: 2024-03-01 23:23:40
Author: rleon
*/

import React, { useCallback, useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const RModal = ({ isOpen, onClose, children }: Props) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 200);
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);


  const handleBackdropClick = (e: any) => {
    if (e.target.id === "backdrop") {
      handleClose();
    }
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div
      id="backdrop"
      className={`fixed inset-0 z-50 overflow-auto bg-black bg-opacity-40 flex items-center justify-center ${isClosing ? 'animate-pop-out' : 'animate-pop'}`}
      onClick={handleBackdropClick}
    >
      <div
        className="w-full  m-auto p-5 transform overflow-auto transition-all opacity-100 scale-100 flex justify-center max-w-3xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-white m-auto w-full break-words bg-background shadow-xl rounded-xl max-w-3xl">
          <div className="mt-2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RModal;
