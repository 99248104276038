/* 
File: CreateUser
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\views
Created at: 2024-02-29 21:36:47
Author: rleon
*/

import { XMarkIcon } from '@heroicons/react/24/outline';
import { ActionIcon } from 'rizzui';
import { useModal } from '../hooks/modal/use-modal';
import i18next from 'i18next';
import { FormEvent } from 'react';
import { rfetch } from '../common/rutils';
import moment from 'moment';


export default function ViewEvent({ startDate, endDate, name, description, onUpdate }: { startDate?: Date, endDate?: Date, name: string, description: string, onUpdate?: () => void }) {
  const { closeModal } = useModal();

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const eventName = (document.getElementById("event-name") as HTMLInputElement).value;
    const startDate = (document.getElementById("start-date") as HTMLInputElement).value;
    const endDate = (document.getElementById("end-date") as HTMLInputElement).value;
    const eventDescription = (document.getElementById("event-description") as HTMLTextAreaElement).value;

    rfetch("/planing/event", JSON.stringify({ eventName, startDate, endDate, eventDescription }), (response) => {
      if (response.success) {
        if (typeof onUpdate === "function") {
          onUpdate();
        }
        closeModal();
      }
    })
  }

  return (
    <>
      <div className="m-auto space-y-1 p-4">
        <div className="pb-5">
          <div>
            <div className="mb-7 flex items-center justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-700">{i18next.t("label.event_overview")}</h2>
              <ActionIcon
                size="sm"
                variant="text"
                className='text-gray-600'
                onClick={() => closeModal()}
              >
                <XMarkIcon className="h-auto w-6" strokeWidth={1.8} />
              </ActionIcon>
            </div>
            <div className="text-base font-semibold leading-7 text-gray-700 mt-5">
              <form onSubmit={submit}>
                <div className="">
                  <div className="border-b border-gray-900/10 pb-12">
                    <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-full">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.event_name")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="event-name"
                            id="event-name"
                            autoComplete="given-name"
                            placeholder={i18next.t("text.put_in_event_name")}
                            defaultValue={name}
                            className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="start-date" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.start_date")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="datetime-local"
                            name="start-date"
                            id="start-date"
                            autoComplete="off"
                            defaultValue={moment(startDate).format("YYYY-MM-DDTHH:mm")}
                            className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="end-date" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.end_date")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="datetime-local"
                            name="end-date"
                            id="end-date"
                            autoComplete="off"
                            defaultValue={moment(endDate).format("YYYY-MM-DDTHH:mm")}
                            className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-full">
                        <label htmlFor="event-description" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.event_description")}
                        </label>
                        <div className="mt-2">
                          <textarea
                            rows={4}
                            id="event-description"
                            name="event-description"
                            autoComplete="off"
                            placeholder={i18next.t("text.put_in_event_description")}
                            defaultValue={description}
                            className="resize-none block w-full rounded-md border-0 placeholder:font-normal py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => closeModal()}>
                    {i18next.t("label.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    {i18next.t("label.save")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>
  )

}