/* 
File: NoLayout
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\Layouts
Created at: 2024-02-29 18:57:19
Author: rleon
*/

import React, { Component } from 'react';


export default function NoLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className='h-full bg-white'>
        {children}
      </div>
    </>
  )

}