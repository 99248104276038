/* 
File: CreateUser
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\views
Created at: 2024-02-29 21:36:47
Author: rleon
*/

import { PhotoIcon, UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { ActionIcon } from 'rizzui';
import { useModal } from '../hooks/modal/use-modal';
import i18next from 'i18next';
import { User } from '../Interfaces/User';
import { rfetch } from '../common/rutils';


export default function CreateUser({ user, onUpdate }: { user?: User, onUpdate?: () => void }) {
  const { closeModal } = useModal();

  const [firstName, setFirstName] = useState(user?.firstname || '');
  const [lastName, setLastName] = useState(user?.lastname || '');
  const [email, setEmail] = useState(user?.email || '');
  const [username, setUsername] = useState(user?.username || '');
  const [organization, setOrganization] = useState(user?.organization_name || '');
  const [locale, setLocale] = useState(user?.locale || 'de');
  const [role, setRole] = useState<string>(user?.role || 'member');
  const [street, setStreet] = useState(user?.street || '');
  const [city, setCity] = useState(user?.city || '');
  const [postalCode, setPostalCode] = useState(user?.postal_code || '');

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const userData = { id: user?.id, firstName, lastName, email, username, locale, role, street, city, postalCode, organization };

    if (user) {
      rfetch("/admin/update-user", JSON.stringify(userData), (response) => {
        if (response.success) {
          window.location.reload();
          if (typeof onUpdate === 'function') {
            onUpdate();
          }
        }
      });
    } else {
      rfetch("/admin/create-user", JSON.stringify(userData), (response) => {
        if (response.success) {
          window.location.reload();
        }
      });
    }
  };

  const handleDeleteUser = () => {
    rfetch("/admin/delete-user", JSON.stringify({ id: user?.id }), (response) => {
      if (response.success) {
        if (typeof onUpdate === 'function') {
          onUpdate();
        }
        closeModal();
      }
    });
  }

  return (
    <>
      <div className="m-auto space-y-1 p-4">
        <div className="pb-5">
          <div>
            <div className="mb-7 flex items-center justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-700">Neuen Benutzer Anlegen</h2>
              <ActionIcon
                size="sm"
                variant="text"
                className='text-gray-600'
                onClick={() => closeModal()}
              >
                <XMarkIcon className="h-auto w-6" strokeWidth={1.8} />
              </ActionIcon>
            </div>
            <div className="text-base font-semibold leading-7 text-gray-700 mt-5">
              <form onSubmit={handleSubmit}>
                <div className="">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{i18next.t("label.personal_information")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{i18next.t("text.personal_information")}</p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                      <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.first_name")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            value={firstName || ''}
                            onChange={(e) => setFirstName(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.last_name")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            value={lastName || ''}
                            onChange={(e) => setLastName(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.email")}
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={email || ''}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.username")}
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600 sm:max-w-md">
                            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">pa-lkmb.de/</span>
                            <input
                              type="text"
                              name="username"
                              id="username"
                              autoComplete="username"
                              value={username || ''}
                              onChange={(e) => setUsername(e.target.value)}
                              onFocus={(e) => e.target.select()}
                              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              placeholder="maxmustermann"
                            />
                          </div>
                          <p className="mt-1 text-xs text-gray-500" id="email-description">
                            Freilassen für Automatische generierung.
                          </p>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.organization")}
                        </label>
                        <div className="mt-2">
                          <input
                            id="organization"
                            name="organization"
                            type="text"
                            autoComplete="organization"
                            value={organization || ''}
                            onChange={(e) => setOrganization(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.language")}
                        </label>
                        <div className="mt-2">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            onChange={(e) => setLocale(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option value="de" selected={locale === 'de'}>Deutsch</option>
                            <option value="en" selected={locale === 'en'}>English</option>
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.role")}
                        </label>
                        <div className="mt-2">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            onChange={(e) => setRole(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option value="member" selected={role === 'member'}>{i18next.t("label.member")}</option>
                            <option value="partner" selected={role === 'partner'}>{i18next.t("label.partner")}</option>
                            <option value="admin" selected={role === 'admin'}>{i18next.t("label.admin")}</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.street_address")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="street-address"
                            id="street-address"
                            autoComplete="street-address"
                            value={street || ''}
                            onChange={(e) => setStreet(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2 sm:col-start-1">
                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.city")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            value={city || ''}
                            onChange={(e) => setCity(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.zip")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            autoComplete="postal-code"
                            value={postalCode || ''}
                            onChange={(e) => setPostalCode(e.target.value)}
                            onFocus={(e) => e.target.select()}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="border-b border-gray-900/10 pb-6">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{i18next.t("label.notifications")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      {i18next.t("text.notificiations")}
                    </p>

                    <div className="mt-3 space-y-10">
                      <fieldset>
                        <div className="mt-3 space-y-3">
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                name="comments"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label htmlFor="comments" className="font-medium text-gray-900">
                                {i18next.t("label.not_new_events")}
                              </label>
                              <p className="text-gray-500">{i18next.t("text.not_new_events")}</p>
                            </div>
                          </div>
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="offers"
                                name="offers"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label htmlFor="offers" className="font-medium text-gray-900">
                                {i18next.t("label.not_new_praxis")}
                              </label>
                              <p className="text-gray-500">{i18next.t("text.not_new_praxis")}</p>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div> */}
                </div>
                <div className='mt-6 flex justify-between'>
                  <div className="inline-flex items-center justify-start gap-x-6">
                    {user &&
                      <button
                        type="button"
                        className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        onClick={handleDeleteUser}>
                        {i18next.t("label.delete_user")}
                      </button>
                    }
                  </div>

                  <div className="inline-flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => closeModal()}>
                      {i18next.t("label.cancel")}
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      {i18next.t("label.save")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>
  )

}