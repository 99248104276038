import i18next from "i18next"
import Layout from "../../Layouts/Layout"
import { useEffect, useMemo, useState } from "react"
import { rfetch } from "../../common/rutils";
import { User } from "../../Interfaces/User";
import moment from "moment";
import { BookmarkIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import RSwitch from "../../components/Switch";
import { useModal } from "../../hooks/modal/use-modal";
import BookPracticalUse from "../../views/BookPracticalUse";
import CreateApprenticeship from "../../views/CreateApprenticeship";


export default function AdminPageApprenticeships() {
    const { openModal } = useModal();
    const [apprenticeships, setApprenticeships] = useState<any[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        rfetch("/admin/apprenticeships", JSON.stringify({}), (response) => {
            if (response.success) {
                setApprenticeships(JSON.parse(response.data));
            }
        })
    };

    const deleteApp = (id: number) => {
        rfetch("/admin/delete-apprenticeship", JSON.stringify({ id }), (response) => {
            if (response.success) {
                fetchData();
            }
        })
    }

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-700">{i18next.t("label.admin.apprenticeships")}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {i18next.t("text.admin.apprenticeships")}
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => openModal({ view: <CreateApprenticeship />, customSize: '80%' })}
                            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {i18next.t("label.add")}
                        </button>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0">
                                            {i18next.t("label.name")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.created_at")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.creator")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {apprenticeships.map((app) => (
                                        <tr key={app.id}>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-700">{app.title}</div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-700">{moment(app.created_at).format("DD.MM.YYYY HH:mm")}</div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-700">{app.creator_username}</div>
                                            </td>
                                            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <button className="text-gray-500 hover:text-teal-600 mx-2" id="rt-edit"
                                                    onClick={() => openModal({ view: <CreateApprenticeship id={app.id} title={app.title} content={app.html} />, customSize: '80%' })}
                                                >
                                                    <PencilSquareIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                </button>
                                                <button className="text-gray-500 hover:text-red-600 mx-2" id="rt-delete"
                                                    onClick={() => deleteApp(app.id)}
                                                >
                                                    <TrashIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip
                anchorSelect="#rt-book-seat"
                content={i18next.t("label.book_seat")}
                delayShow={500}
            />
            <Tooltip
                anchorSelect="#rt-edit"
                content={i18next.t("label.edit")}
                delayShow={500}
            />
            <Tooltip
                anchorSelect="#rt-delete"
                content={i18next.t("label.delete")}
                delayShow={500}
            />
        </Layout>
    )
}
