import { ArchiveBoxIcon, BookOpenIcon, CalendarIcon, ClipboardDocumentListIcon, HomeIcon, QuestionMarkCircleIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/24/outline";
import { config } from "./default.config";

interface NavigationItem {
    title?: boolean;
    name: string;
    translationKey: string;
    path: string;
    icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
        title?: string | undefined;
        titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>>
    current: boolean;
    children?: NavigationItem[],
    roles?: string[]
}

export const navigationRoutes: NavigationItem[] = [
    {
        title: true,
        name: 'NAVIGATION',
        translationKey: 'label.navigation',
        path: '/',
        current: false,
    },
    {
        name: 'Dashboard',
        translationKey: 'label.dashboard',
        path: config.appPath + '/dashboard',
        icon: HomeIcon,
        current: false
    },
    {
        name: 'Filemanager',
        translationKey: 'label.filemanager',
        path: config.appPath + '/filemanager',
        icon: ArchiveBoxIcon,
        current: false,
        roles: ['member', 'admin']
    },
    {
        name: 'Calendar',
        translationKey: 'label.calendar',
        path: config.appPath + '/calendar',
        icon: CalendarIcon,
        current: false,
    },
    // {
    //     title: true,
    //     name: 'VERWALTUNG',
    //     translationKey: 'label.management',
    //     path: '/',
    //     current: false,
    //     roles: ['partner', 'admin']
    // },
    // {
    //     name: 'manage_contact_persons',
    //     translationKey: 'label.contact_person',
    //     path: config.appPath + '/management/contact_persons',
    //     icon: UsersIcon,
    //     current: false,
    //     roles: ['partner', 'admin']

    // },
    {
        title: true,
        name: 'ADMINISTRATION',
        translationKey: 'label.administration',
        path: '/',
        current: false,
        roles: ['admin']
    },
    {
        name: 'admin_users',
        translationKey: 'label.admin.users',
        path: config.appPath + '/admin/users',
        icon: UsersIcon,
        current: false,
        roles: ['admin']

    },

    // {
    //     name: 'admin_apprenticeships',
    //     translationKey: 'label.admin.apprenticeships',
    //     path: config.appPath + '/admin/apprenticeships',
    //     icon: BookOpenIcon,
    //     current: false,
    //     roles: ['admin']

    // },
    // {
    //     name: 'admin_seminars',
    //     translationKey: 'label.admin.seminars',
    //     path: '/app/admin/seminars',
    //     icon: UserGroupIcon,
    //     current: false,
    //     roles: ['admin']

    // },
    {
        name: 'admin_practical_applications',
        translationKey: 'label.admin.practical_applications',
        path: '/app/admin/practical_applications',
        icon: ClipboardDocumentListIcon,
        current: false,
        roles: ['admin']
    },
    {
        name: 'admin_apprenticeships',
        translationKey: 'label.admin.apprenticeships',
        path: '/app/admin/apprenticeships',
        icon: BookOpenIcon,
        current: false,
        roles: ['admin']

    },
    {
        name: 'admin_faqs',
        translationKey: 'label.admin.faqs',
        path: '/app/admin/faqs',
        icon: QuestionMarkCircleIcon,
        current: false,
        roles: ['admin']

    },
];