import toast from "react-hot-toast";
import { RResponse } from "../Interfaces/RResponse";
import { User } from "../Interfaces/User";

export const getLoggedInUser = (): User | null => {
    const user = localStorage.getItem('authUser');
    // Überprüfe, ob `user` ein String ist, der in gültiges JSON geparst werden kann
    if (user && user !== "undefined" && user !== "null") {
        try {
            return JSON.parse(user) as User;
        } catch (e) {
            // Fehler beim Parsen von JSON, wahrscheinlich ungültiges JSON-Format
            console.error("Error parsing user data:", e);
            return null;
        }
    }
    return null;
};


export const getUserAgent = () => {
    return navigator.userAgent + ` Program/${process.env.REACT_APP_APPNAME}`
}

type Data = {
    success: boolean;
    data: any;
    message?: string | any;
}

export const rfetch = (
    url: string,
    body: any,
    callback: (data: Data) => void,
    action: string = 'POST',
) => {
    const storedUser = getLoggedInUser();
    const requestURL = process.env.REACT_APP_API_URL + url;
    const debug = process.env.NODE_ENV === 'development';

    let a = JSON.parse(body);
    const b = JSON.stringify({
        ...(typeof a === 'object' ? a : {}),
        sender: storedUser?.id || 'none',
        origin: window.location.origin,
        userAgent: getUserAgent(),
    });

    if (debug) {
        console.log("Sending Request to:", requestURL, "with body:", b);
    }

    fetch(requestURL, {
        method: action,
        headers: {
            'Content-Type': 'application/json',
        },
        body: action === 'GET' ? null : b
    })
        .then(response => {
            return response.json();
        })
        .then(data => {
            callback(data as RResponse);
        })
        .catch(error => {
            console.error('Ein Fehler ist aufgetreten:', error);
        });
};

export async function cfetch(url: string, data: any) {
    try {
        const storedUser = getLoggedInUser();
        const requestURL = process.env.REACT_APP_API_URL + url;
        const debug = process.env.NODE_ENV === 'development';

        const b = JSON.stringify({
            ...(typeof data === 'object' ? data : {}),
            sender: storedUser?.id || 'none',
            origin: window.location.origin,
            userAgent: getUserAgent()

        });
        const response = await fetch(requestURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: b, // Daten müssen bereits als JSON-String vorliegen
        });

        // Warte auf die Antwort und parse sie als JSON
        const responseData = await response.json();

        // Überprüfe den Erfolg der Operation basierend auf der Antwort des Servers
        if (responseData.success) {
            return responseData; // Gebe die Antwort zurück, wenn der Request erfolgreich war
        } else {
            throw new Error('Service not available'); // Wirf einen Fehler, wenn der Request fehlschlug
        }


    } catch (error: any) {
        throw new Error(error.message || 'An error occurred while fetching the data.');
    }
}

export async function formDatafetch(url: string, data: any) {
    try {
        const storedUser = getLoggedInUser();
        const requestURL = process.env.REACT_APP_API_URL + url;
        const debug = process.env.NODE_ENV === 'development';

        // const b = JSON.stringify({
        //     ...(typeof data === 'object' ? data : {}),
        //     sender: storedUser?.id || 'none',
        //     origin: window.location.origin,
        //     userAgent: getUserAgent()

        // });
        const response = await fetch(requestURL, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json'
            },
            body: data, // Daten müssen bereits als JSON-String vorliegen
        });

        // Warte auf die Antwort und parse sie als JSON
        const responseData = await response.json();

        // Überprüfe den Erfolg der Operation basierend auf der Antwort des Servers
        if (responseData.success) {
            return responseData; // Gebe die Antwort zurück, wenn der Request erfolgreich war
        } else {
            throw new Error('Service not available'); // Wirf einen Fehler, wenn der Request fehlschlug
        }


    } catch (error: any) {
        throw new Error(error.message || 'An error occurred while fetching the data.');
    }
}

// export async function download(url: string) {
//     try {
//         const storedUser = getLoggedInUser();
//         const requestURL = process.env.REACT_APP_API_URL + url;
//         const debug = process.env.NODE_ENV === 'development';

//         const response = await fetch(requestURL, {
//             method: 'GET',
//             headers: {
//                 // 'Content-Type': 'application/json'
//             },
//             body: null, // Daten müssen bereits als JSON-String vorliegen
//         });

//         // Warte auf die Antwort und parse sie als JSON
//         const responseData = await response.json();

//         // Überprüfe den Erfolg der Operation basierend auf der Antwort des Servers
//         if (responseData.success) {
//             return responseData; // Gebe die Antwort zurück, wenn der Request erfolgreich war
//         } else {
//             throw new Error('Service not available'); // Wirf einen Fehler, wenn der Request fehlschlug
//         }


//     } catch (error: any) {
//         throw new Error(error.message || 'An error occurred while fetching the data.');
//     }
// }

export function fetchUserIP() {
    return fetch(`https://api.ipify.org?format=json`).then(res => res.json());
}

export function formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Copies the specified text to the clipboard.
 * 
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {Promise<void>} - A promise that resolves when the text has been copied successfully, or rejects with an error if copying fails.
 */
export async function copyToClipboard(text: string): Promise<void> {
    if (navigator.clipboard) {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('In die Zwischenablage kopiert.')
        } catch (err) {
            toast.error('Fehler beim Kopieren in die Zwischenablage.')
        }
    } else {
        toast.error('Clipboard API nicht unterstützt.')
    }
}