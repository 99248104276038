'use client';

import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { getLoggedInUser, rfetch } from '../common/rutils';
import { User } from '../Interfaces/User';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { protectedRoutes } from '../config/routes.config';

interface AuthContextType {
    user: User | null;
    signIn: (username: string, password: string) => Promise<void>;
    signOut: () => void;
    updateUser: (user: any) => Promise<boolean>;
    explicitUpdateUser: (url: string, user: any) => Promise<boolean>;
    getUserByUsername: (username: string) => Promise<User | null>;
    getUser: (userId: string) => Promise<User | null>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkAuthentication = async () => {

            if (protectedRoutes.some(x => x.path === location.pathname) && getLoggedInUser() == null) {
                navigate("/login")
            } else {

                const _user = JSON.parse(localStorage.getItem("authUser") as string) as User;
                setUser(_user);
            }
        };

        checkAuthentication();

        if (user) {
            fetchUserData(user?.id as number);
        }
    }, []);

    const fetchUserData = (userId: number) => {
        rfetch("/user/fetch", JSON.stringify({ userId: userId }), (response) => {
            if (response.success) {
                localStorage.setItem('authUser', response.data);
                setUser(response.data as User);
            } else {
                console.error("Fehler beim Laden der Benutzerdaten:", response.message);
            }
        });
    };

    const signIn = async (username: string, password: string) => {
        rfetch("/auth/login", JSON.stringify({ username, password }), (response) => {
            if (response.success) {
                localStorage.setItem('authUser', response.data);
                setUser(response.data);
                window.location.href = '/app'; // Optional: Leite um nach erfolgreicher Anmeldung
            } else {
                toast.error(i18next.t(response.message));
            }
        });
    };

    const signOut = () => {
        window.location.href = '/';
        localStorage.removeItem('authUser');
        setUser(null); // Entferne den Benutzer aus dem Zustand
    };

    const updateUser = (user: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            rfetch("/user/performUpdate", JSON.stringify({ param: JSON.stringify(user) }), (response) => {
                if (response.success) {
                    localStorage.setItem('authUser', JSON.stringify(user));
                    setUser(user);
                    resolve(true);
                } else {
                    console.error('Update fehlgeschlagen:', response.message);
                    resolve(false);
                }
            });
        });
    }

    const explicitUpdateUser = (url: string, user: any,): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            rfetch(url, JSON.stringify({ param: JSON.stringify(user) }), (response) => {
                if (response.success) {
                    localStorage.setItem('authUser', JSON.stringify(user));
                    setUser(user);
                    resolve(true);
                } else {
                    console.error('Update fehlgeschlagen:', response.message);
                    resolve(false);
                }
            });
        });
    }

    const getUserByUsername = (username: string): Promise<User | null> => {
        return new Promise((resolve, reject) => {
            rfetch("/user/fetch", JSON.stringify({ param: JSON.stringify(username) }), (response) => {
                if (response.success) {
                    resolve(response.data as User);
                } else {
                    resolve(null);
                }
            });
        });
    }

    const getUser = (userId: string): Promise<User | null> => {
        return new Promise((resolve, reject) => {
            rfetch("/user/fetch", JSON.stringify({ param: userId }), (response) => {
                if (response.success) {
                    resolve(response.data as User);
                } else {
                    resolve(null);
                }
            });
        });
    }

    if (isLoading) {
        return <AuthContext.Provider value={{ user, signIn, signOut, updateUser, getUser, getUserByUsername, explicitUpdateUser }}>{children}</AuthContext.Provider>;
    }

    return (
        <AuthContext.Provider value={{ user, signIn, signOut, updateUser, getUser, getUserByUsername, explicitUpdateUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth muss innerhalb eines AuthProvider verwendet werden');
    }
    return context;
};