import { SVGProps, useEffect, useState } from 'react'
import { Dialog, Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, CheckCircleIcon, InformationCircleIcon, ChevronDownIcon, MinusSmallIcon, PlusSmallIcon, ChevronRightIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline'

import logo from '../../assets/img/logo-light-full.png';
import logoDark from '../../assets/img/logo-dark-full.png';
import hero1 from '../../assets/images/landing/hero1.jpg';
import Divider from '../../components/Divider';
import i18next from 'i18next';

import awo from '../../assets/images/landing/logos/awo.png';
import berufsfachschulen from '../../assets/images/landing/logos/berufsfachschulen.png';
import ggsd from '../../assets/images/landing/logos/ggsd.png';
import human_care from '../../assets/images/landing/logos/human_care.png';
import kbo from '../../assets/images/landing/logos/kbo.png';
import medicalpark from '../../assets/images/landing/logos/medicalpark.png';
import rupertihof from '../../assets/images/landing/logos/rupertihof.png';
import schwaighof from '../../assets/images/landing/logos/schwaighof.png';
import pbp from '../../assets/img/PB_Placeholder.png';
import schmidl from '../../assets/img/Schmidl.jpg';
import Schoettl from '../../assets/img/Schoettl.jpg';
import { getCurrentYear } from '../../components/Footer';
import { FaHeart } from 'react-icons/fa';
import { useAuth } from '../../Providers/AuthContext';
import { Link } from 'react-router-dom';
import { rfetch } from '../../common/rutils';

type apprenticeships = {
    name: string;
    href: string;
}

type faq = {
    question: string;
    answer: string;
}

export default function LandingPage() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const { user } = useAuth();
    const [apprenticeships, setApprenticeships] = useState<apprenticeships[]>([]);
    const [faqs, setFaqs] = useState<faq[]>([]);

    useEffect(() => {
        rfetch("/get-apprenticeships", JSON.stringify({}), (response) => {
            if (response.success) {
                const _D = JSON.parse(response.data);
                const list = _D.map((l: any) => ({ name: l.title, href: `apprenticeship?id=${l.id}` }));
                setApprenticeships(list);
            }
        })

        rfetch("/admin/faqs", JSON.stringify({}), (response) => {
            if (response.success) {
                setFaqs(JSON.parse(response.data));
            }
        })
    }, []);

    const navigation = [
        { name: 'Pflegeberufegesetz', href: '#gesetz' },
        { name: i18next.t("label.contact_persons"), href: '#contact_persons' },
        { name: i18next.t("label.faq"), href: '#faq' },
        { name: i18next.t("label.verbundsmitglieder"), href: '#members' },
    ]

    const people = [
        {
            name: 'Gabriele Schmidl',
            role: 'I. Vorsitzende',
            imageUrl: schmidl,
            bio: 'Schulleitung Berufsfachschulen Krankenhaus Agatharied',
        },
        {
            name: 'Michaela Schöttl',
            role: 'II. Vorsitzende',
            imageUrl: Schoettl,
            bio: 'Berufsfachschulen für Pflege GGSD Miesbach',
        },
    ]

    // const faqs = [
    //     {
    //         question: "Gründe für eine Ausbildung in der Pflege",
    //         answers:
    //             [
    //                 "Sie sind kreativ, flexibel und neugierig, um mit Menschen aller Altersgruppen Kontakt zu halten und zu arbeiten",
    //                 "Sie nehmen vielfältigste Aufgaben in der Pflege wahr",
    //                 "Der pflegewissenschaftliche und medizinische Fortschritt bietet Ihnen einen fundierten theoretischen Hintergrund",
    //                 "Es gibt nach dem erfolgreichen Abschluss unterschiedlichste Fort- und Weiterbildungsmöglichkeiten"
    //             ]
    //     },
    //     {
    //         question: "Ablauf der Ausbildung",
    //         answers: [
    //             "Die Generalistische Pflegeausbildung startet zum 01.September des Jahres. Theorie- und Praxisphasen wechseln sich in der Ausbildung ab. An deren Ende schließen Sie mit einer staatlichen Prüfung ab, die aus einem schriftlichen, mündlichen und praktischen Anteil besteht.",
    //             "Sie bauen nach und nach geforderte Kompetenzen in fünf Bereichen auf, mit denen Sie später im Beruf handlungsfähig sind.",
    //             "In den Berufsfachschulen für Pflege findet der theoretische Unterricht statt.",
    //             "Die praktischen Einsätze finden bei dem Träger der praktischen Ausbildung und den Kooperationspartnern des Pflegeausbildungsverbundes statt."
    //         ],
    //     },
    //     {
    //         question: "Zugangsvoraussetzungen",
    //         answers: [
    //             "Mit einem mittleren Schulabschluss",
    //             "Mit einem erfolgreichen Abschluss der Mittelschule sowie den Nachweis",
    //             {
    //                 options: [
    //                     "einer erfolgreich abgeschlossenen Berufsausbildung von mindestens zweijähriger Dauer",
    //                     "oder einer erfolgreich abgeschlossenen Pflegehilfeausbildung von mindestens einjähriger Dauer",
    //                 ]
    //             },
    //             "Oder mit einem erfolgreichen Abschluss einer sonstigen zehnjährigen allgemeinen Schulbildung.",
    //             "Notwendige Impfungen: Masern, Mumps, Röteln, evtl. Hepatitis B"
    //         ],
    //     },
    //     {
    //         question: "Berufsbild",
    //         answers: [
    //             "Ermittlung, Planung und Evaluation der Pflege",
    //             "Durchführung körpernaher Pflege und vorbeugen von Erkrankungen",
    //             "Versorgung von Wunden, Assistenz bei Untersuchungen, Medikamentengabe, Infusionen verabreichen, Injektionen "
    //         ]
    //     },
    //     {
    //         question: "Vergütung",
    //         answers: [
    //             "Sie erhalten eine tariflich geregelte und feste Ausbildungsvergütung, die sich nach dem jeweiligen Ausbildungsjahr richtet.",
    //             "Der genaue Betrag ist im Ausbildungsvertrag geregelt und vom Tarifvertrag des Trägers der praktischen Ausbildung abhängig.",
    //         ],
    //     },
    //     // More questions...
    // ]

    const createFooterNavigation = () => {
        const dashboardLinks = [];

        if (!user) {
            dashboardLinks.unshift({ name: i18next.t("label.login"), href: '/login' });
        } else {
            dashboardLinks.unshift({ name: i18next.t("label.logout"), href: '/logout' });
            dashboardLinks.unshift({ name: i18next.t("label.events"), href: '/app/calendar' });
            dashboardLinks.unshift({ name: i18next.t("label.dashboard"), href: '/app' });
        }

        return {
            dashboard: dashboardLinks,
            ausbildungen: apprenticeships,
            asubildungen2: [
                { name: 'Operationstechnischer Assistent', href: '#' },
                { name: 'Intensivpflegekraft', href: '#' },
            ],
            legal: [
                { name: i18next.t("label.privacy"), href: '/privacy' },
                { name: i18next.t("label.impress"), href: '/impress' },
            ]
        };
    };

    const footerNavigation = createFooterNavigation();

    return (
        <>
            <div className="bg-gray-900 hero ">
                <div className='backdrop-brightness-50'>
                    <header className="absolute inset-x-0 top-0 z-50">
                        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                            <div className="flex lg:flex-1">
                                <a href="#" className="-m-1.5 p-1.5">
                                    <span className="sr-only">PA LKMB</span>
                                    <img
                                        className="h-8 w-auto"
                                        src={logo}
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className="flex lg:hidden">
                                <button
                                    type="button"
                                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                                    onClick={() => setMobileMenuOpen(true)}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="hidden lg:flex lg:gap-x-12">
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                                {(user?.id) ? (
                                    <Link to="/app" className="text-sm font-semibold leading-6 text-white">
                                        {i18next.t("label.dashboard")} <span aria-hidden="true">&rarr;</span>
                                    </Link>
                                ) : (
                                    <Link to="/login" className="text-sm font-semibold leading-6 text-white">
                                        {i18next.t("label.login")} <span aria-hidden="true">&rarr;</span>
                                    </Link>
                                )}

                            </div>
                        </nav>
                        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                            <div className="fixed inset-0 z-50" />
                            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                                <div className="flex items-center justify-between">
                                    <a href="#" className="-m-1.5 p-1.5">
                                        <span className="sr-only">PA LKMB</span>
                                        <img
                                            className="h-8 w-auto"
                                            src={logo}
                                            alt=""
                                        />
                                    </a>
                                    <button
                                        type="button"
                                        className="-m-2.5 rounded-md p-2.5 text-gray-400"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mt-6 flow-root">
                                    <div className="-my-6 divide-y divide-gray-500/25">
                                        <div className="space-y-2 py-6">
                                            {navigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                                >
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                        <div className="py-6">
                                            {(user?.id) ? (
                                                <Link
                                                    to="/app"
                                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                                >
                                                    {i18next.t("label.dashboard")}
                                                </Link>
                                            ) : (
                                                <Link
                                                    to="/login"
                                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                                                >
                                                    {i18next.t("label.login")}
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Dialog>
                    </header>

                    <div className={`relative isolate overflow-hidden pt-14`}>
                        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-green-500 ring-1 ring-green-100/40">
                                    KONZEPT FÜR IHREN ERFOLG
                                </div>
                            </div>
                            <div className="text-center">
                                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                                    Ausbildungsverbund Pflege im Landkreis Miesbach
                                </h1>
                                <p className="mt-6 text-lg leading-8 text-gray-300">
                                    Im Bereich Pflege sind Ausbildungen der ideale Start in eine gute berufliche Zukunft!
                                </p>
                                <div className="mt-10 flex items-center justify-center gap-x-6">
                                    {/* <Link to="#" className="text-sm font-semibold leading-6 text-white">
                                        Learn more <span aria-hidden="true">→</span>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                        <div
                            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <section id='gesetz'>
                <div className="bg-white px-6 py-32 lg:px-8">
                    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 text-center">
                        <h2 className="mt-2 text-2xl font-bold tracking-tight text-gray-700 sm:text-4xl">Pflegeberufegesetz 2020</h2>
                        <p className="mt-6 text-lg leading-8">
                            Mit dem neuen Pflegeberufsgesetz im Jahr 2020 entstand durch die Zusammenführung der drei bisherigen Pflegefachberufe aus den Bereichen „Altenpflege“, „Gesundheits- und Krankenpflege“ und „Gesundheits- und Kinderkrankenpflege“ ein neues Berufsbild in der Pflege.
                        </p>
                        <p className="mt-6 text-lg leading-8">
                            Die Pflegeausbildung zur Pflegefachfrau oder zum Pflegefachmann vermittelt die notwendigen Kompetenzen zur Pflege aller Altersgruppen und ermöglicht nach erfolgreichem Abschluss der generalistischen Ausbildung einen flexiblen Einsatz in allen Versorgungsbereichen wie z.B. im Krankenhaus, im Pflegeheim oder ambulant in der eigenen Wohnung.
                        </p>
                    </div>
                </div>
            </section >
            <section id='contact_persons'>
                <div className="bg-white py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl sm:text-center">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl">{i18next.t("label.contact_persons")}</h2>
                            <p className="mt-6 text-md leading-8 text-gray-600">
                                {i18next.t("text.contact_persons")}
                            </p>
                        </div>
                        <Divider icon={ChevronDownIcon} />
                        <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
                        >
                            {people.map((person) => (
                                <li key={person.name} className="flex flex-col gap-6 xl:flex-row">
                                    <img className="w-52 flex-none rounded-2xl object-cover shadow-lg" src={person.imageUrl} alt="" width={200} />
                                    <div className="flex-auto">
                                        <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                        <p className="text-base leading-7 text-gray-600">{person.role}</p>
                                        <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
            <section id="faq">
                <div className="bg-white">
                    <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
                        <div className="mx-auto max-w-4xl text-center">
                            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-700">{i18next.t("label.faq")}</h2>
                            <p className="mt-6 text-md leading-8 text-gray-600">
                                {i18next.t("text.faq")}
                            </p>
                            <Divider icon={ChevronDownIcon} />
                            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                                {faqs.map((faq) => (
                                    <Disclosure as="div" key={faq.question} className="pt-6">
                                        {({ open }) => (
                                            <>
                                                <dt>
                                                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                        <span className="ml-6 flex h-7 items-center">
                                                            {open ? (
                                                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                            ) : (
                                                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </dt>
                                                <Disclosure.Panel as="div" className="mt-2 pr-12 text-left ">
                                                    <div className="relative">
                                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                            <div className="w-full border-t border-gray-300" />
                                                        </div>
                                                        <div className="relative flex justify-center">
                                                            <span className="bg-white px-2 text-gray-500">
                                                                <ArrowDownCircleIcon className="h-5 w-5 text-gray-500 " aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="my-5 p-5 text-base leading-7 text-gray-700" dangerouslySetInnerHTML={{ __html: faq.answer }}>
                                                    </div>
                                                    {/* {faq.answers &&
                                                        <>
                                                            {faq.answers.map(x => {
                                                                if (typeof x === 'string') {
                                                                    return (<p className="text-base leading-7 my-5 text-gray-600"><ChevronRightIcon className='pr-3 h-6 w-6 inline-block m-auto font-bold text-gray-800' aria-hidden="true" />{x}</p>)
                                                                } else {
                                                                    return (
                                                                        <>
                                                                            <ul className='divide-y divide-gray-300/50 ml-5'>
                                                                                {x.options.map(y => (
                                                                                    <li key={Math.random()} className='flex justify-between gap-x-3 py-2.5 text-gray-600'>{y}</li>
                                                                                ))}
                                                                            </ul>
                                                                        </>
                                                                    )
                                                                }

                                                            })}
                                                        </>
                                                    } */}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </section>
            <section id="members">
                <div className="bg-white py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                            {i18next.t("label.verbundsmitglieder")}
                        </h2>
                        <p className="mt-3 text-md leading-8 text-gray-600 text-center">
                            {i18next.t("text.verbundsmitglieder")}
                        </p>
                        <Divider icon={ChevronDownIcon} />
                        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center justify-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                            <img
                                className="col-span-2 max-h-48 w-full object-contain lg:col-span-1"
                                src={awo}
                                alt="Transistor"
                                width={200}
                                height={200}
                            />
                            <img
                                className="col-span-2 max-h-48 w-full object-contain lg:col-span-1"
                                src={berufsfachschulen}
                                alt="Reform"
                                width={200}
                                height={200}
                            />
                            <img
                                className="col-span-2 max-h-48 w-full object-contain lg:col-span-1"
                                src={ggsd}
                                alt="Tuple"
                                width={200}
                                height={200}
                            />
                            <img
                                className="col-span-2 max-h-48 w-full object-contain sm:col-start-2 lg:col-span-1"
                                src={human_care}
                                alt="SavvyCal"
                                width={200}
                                height={200}

                            />
                            <img
                                className="col-span-2 max-h-48 w-full object-contain sm:col-start-2 lg:col-span-1"
                                src={medicalpark}
                                alt="SavvyCal"
                                width={200}
                                height={200}

                            />
                            <img
                                className="col-span-2 max-h-48 w-full object-contain sm:col-start-2 lg:col-span-1"
                                src={rupertihof}
                                alt="SavvyCal"
                                width={200}
                                height={200}

                            />
                            <img
                                className="col-span-2 max-h-48 w-full object-contain sm:col-start-2 lg:col-span-1"
                                src={schwaighof}
                                alt="SavvyCal"
                                width={200}
                                height={200}

                            />
                            <img
                                className="col-span-2 col-start-2 max-h-48 w-full object-contain sm:col-start-auto lg:col-span-1"
                                src={kbo}
                                alt="Statamic"
                                width={200}
                                height={200}
                            />
                        </div>
                        <div className="mt-16 flex justify-center">
                            <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
                                <span className="hidden md:inline">{i18next.t("text.verbundsmitglieder_2")}</span>
                                <Link to="/verbunds_mitglieder" className="font-semibold text-indigo-600">
                                    <span className="absolute inset-0" aria-hidden="true" /> {i18next.t("label.read_more")}{' '}
                                    <span aria-hidden="true">&rarr;</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="bg-white" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="space-y-8">
                            <img
                                className="h-7"
                                src={logoDark}
                                alt="Company name"
                            />
                            <p className="text-sm leading-6 text-gray-600">
                                {i18next.t("text.footer_slogan")}
                            </p>
                        </div>
                        <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                <div>
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.intern")}</h3>
                                    <ul className="mt-6 space-y-4">
                                        {footerNavigation.dashboard.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-700">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.apprenticeships")}</h3>
                                    <ul className="mt-6 space-y-4">
                                        {footerNavigation.ausbildungen.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-700">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-2 md:gap-8">
                                {/* <div>
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.apprenticeships_coop")}</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {footerNavigation.asubildungen2.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-700">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                                <div className="mt-10 md:mt-0">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.legal")}</h3>
                                    <ul className="mt-6 space-y-4">
                                        {footerNavigation.legal.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 ">
                        <p className="text-xs leading-5 text-gray-500 float-left">2023 - {getCurrentYear()} &copy; Ausbildungsverbund Landkreis Miesbach - Alle Rechte vorbehalten.</p>
                        <p className="text-center text-xs leading-5 text-gray-500 float-right">
                            Crafted with <FaHeart className="text-red-500 inline-block" /> by {' '}
                            <a href="https://risingcore.eu/" target="_blank" className="text-blue-500 hover:text-blue-600" rel="noreferrer">Risingcore</a>
                        </p>
                    </div>
                </div>
            </footer>
        </>
    )
}