/* 
File: Sidebar
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\components
Created at: 2024-02-27 12:50:00
Author: rleon
*/
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { navigationRoutes } from '../../config/navigiation.config'
import i18next from 'i18next'
import { Link } from 'react-router-dom'
import { cn } from 'rizzui'
import logo from '../../assets/img/logo-dark-full.png';
import { useAuth } from '../../Providers/AuthContext'
import Footer from '../Footer'

type Props = {
  children: React.ReactNode
}

export default function Sidebar({ children }: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { user } = useAuth();

  const userNavigation = [
    { name: i18next.t("label.logout"), href: '/logout' },
  ]

  return (
    <div className='h-full'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={logo}
                      alt="LKMB"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigationRoutes.map((item) => {
                            // Direktes Rückgabe-Element ohne zusätzliche Prüfung für item.title
                            if (!item.roles || item.roles?.includes(user?.role || "")) {
                              if (item.title) {
                                // Schlüssel direkt in das zurückgegebene Element einfügen
                                return (
                                  <div key={item.name} className="text-xs font-semibold leading-6 text-gray-400 mx-3 pb-2 pt-3">
                                    {i18next.t(item.translationKey)}
                                  </div>
                                );
                              } else {
                                return (
                                  <li key={item.name}>
                                    <Link
                                      to={item.path}
                                      className={cn(
                                        item.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                      )}
                                    >
                                      {item.icon && (
                                        <item.icon
                                          className={cn(
                                            item.current ? 'text-emerald-600' : 'text-gray-400 group-hover:text-emerald-600',
                                            'h-6 w-6 shrink-0'
                                          )}
                                          aria-hidden="true"
                                        />
                                      )}
                                      {i18next.t(item.translationKey)}
                                    </Link>
                                  </li>
                                );
                              }
                            }
                            return null; // Fügt eine Rückgabe hinzu, um sicherzustellen, dass die Funktion immer etwas zurückgibt
                          })}
                        </ul>
                      </li>
                      {/* <li className="mt-auto">
                        <Link
                          to="/settings"
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-emerald-600"
                        >
                          <Cog6ToothIcon
                            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-emerald-600"
                            aria-hidden="true"
                          />
                          {i18next.t("label.settings")}
                        </Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto"
              src={logo}
              alt="LKMB"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigationRoutes.map((item) => {
                    // Direktes Rückgabe-Element ohne zusätzliche Prüfung für item.title
                    if (!item.roles || item.roles?.includes(user?.role || "")) {
                      if (item.title) {
                        // Schlüssel direkt in das zurückgegebene Element einfügen
                        return (
                          <div key={item.name} className="text-xs font-semibold leading-6 text-gray-400 mx-3 pb-2 pt-3">
                            {i18next.t(item.translationKey)}
                          </div>
                        );
                      } else {
                        return (
                          <li key={item.name}>
                            <Link
                              to={item.path}
                              className={cn(
                                item.current ? 'bg-gray-50 text-teal-600' : 'text-gray-700 hover:text-emerald-600 hover:bg-gray-50',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              )}
                            >
                              {item.icon && (
                                <item.icon
                                  className={cn(
                                    item.current ? 'text-emerald-600' : 'text-gray-400 group-hover:text-emerald-600',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                              {i18next.t(item.translationKey)}
                            </Link>
                          </li>
                        );
                      }
                    }
                    return null; // Fügt eine Rückgabe hinzu, um sicherzustellen, dass die Funktion immer etwas zurückgibt
                  })}

                </ul>
              </li>
              {/* <li className="mt-auto">
                <Link
                  to="/settings"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-emerald-600"
                >
                  <Cog6ToothIcon
                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-emerald-600"
                    aria-hidden="true"
                  />
                  {i18next.t("label.settings")}
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </div >

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className='relative flex flex-1'></div>

            <div className="flex items-center gap-x-4 lg:gap-x-6">

              {/* Separator */}
              {/* <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" /> */}

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src={user?.profile_picture as string}
                    alt=""
                  />
                  <span className="hidden lg:flex lg:items-center">
                    <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                      {user?.username}
                    </span>
                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={cn(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10 h-full">
          <div className="px-4 sm:px-6 lg:px-8 min-h-screen">{children}</div>
        </main>
        <Footer />

      </div>
    </div>
  )
}
