'use client';

import { useEffect } from 'react';
import { Modal } from 'rizzui';
import { useModal } from './use-modal';
import RModal from '../../components/RModal';

export default function GlobalModal() {
  const { isOpen, view, closeModal, customSize } = useModal();
  // const pathname = usePathname();
  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RModal
      isOpen={isOpen}
      onClose={closeModal}
    >
      {view}
    </RModal>
    //     <Modal
    //       isOpen={isOpen}
    //       onClose={closeModal}
    //       customSize={customSize || '40%'}
    //       overlayClassName="bg-opacity-40 backdrop-blur-lg bg-gray-900"
    //       containerClassName="bg-white text-gray-100 rounded-lg"
    //       className="z-[9999]"
    //     >
    // {view}

    //     </Modal>
  );
}
