/* 
File: Layout
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\Layouts
Created at: 2024-02-29 18:52:42
Author: rleon
*/

import React from 'react';
import Sidebar from '../components/Sidebar/Sidebar';


export default function Layout(
  {
    children
  }: {
    children: React.ReactNode
  }) {
  return (
    <>
      <Sidebar>
        {children}
      </Sidebar>
    </>
  )

}