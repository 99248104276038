/* 
File: verbundsMitglieder
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\pages\Landing
Created at: 2024-03-17 22:28:54
Author: rleon
*/

import React, { Component } from 'react';
import FrontLayout from '../../Layouts/FrontLayout';

import awo from '../../assets/images/landing/logos/awo.png';
import berufsfachschulen from '../../assets/images/landing/logos/berufsfachschulen.png';
import ggsd from '../../assets/images/landing/logos/ggsd.png';
import human_care from '../../assets/images/landing/logos/human_care.png';
import kbo from '../../assets/images/landing/logos/kbo.png';
import medicalpark from '../../assets/images/landing/logos/medicalpark.png';
import rupertihof from '../../assets/images/landing/logos/rupertihof.png';
import schwaighof from '../../assets/images/landing/logos/schwaighof.png';
import brk from '../../assets/images/landing/logos/brk.png';
import st_elisabeth from '../../assets/images/landing/logos/st_elisabeth.png';
import keinBild from '../../assets/img/kein-bild.png';
import { EnvelopeIcon, GlobeAltIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { copyToClipboard } from '../../common/rutils';



const people = [
  {
    name: 'Seniorenzentrum Sauerlach soziale Zukunft gGmbH',
    imageUrl: keinBild,  // No logo
    link: 'https://awo-obb.de',
    mail: 'abt.altenhilfe@awo-obb.de',
    phone: '08104 - 88930'
  },
  {
    name: 'Kath. Integrations-Kindertageseinrichtung Ich & Du - Agatharied',
    imageUrl: keinBild,  // No logo
    link: 'https://kita.ebmuc.de',
    mail: 'ich-und-du.agatharied@kita.ebmuc.de',
    phone: '08026 - 9298757'
  },
  {
    name: 'Regens Wagner Erlkam',
    imageUrl: keinBild,  // No logo
    link: 'https://regens-wagner.de',
    mail: 'rw-erlkam@regens-wagner.de',
    phone: '08024 - 904200'
  },
  {
    name: 'Alten- und Pflegeheim St. Elisabeth Schliersee',
    imageUrl: st_elisabeth,
    link: 'https://st-zeno-schliersee.de',
    mail: 'deckert@st-zeno-schliersee.de',
    phone: '08026 - 9757 11'
  },
  {
    name: 'Krankenhaus Agtharied KU',
    imageUrl: berufsfachschulen,  // No logo
    link: 'https://khagatharied.de',
    mail: 'vorstand@khagatharied.de',
    phone: '08026 - 3933000'
  },
  {
    name: 'Berufsfachschule für Pflege Agatharied',
    imageUrl: berufsfachschulen,
    link: 'https://khagatharied.de',
    mail: 'schule@khagatharied.de',
    phone: '08026 - 9393190'
  },
  {
    name: 'AWO-Seniorenzentrum Inge-Gabert-Haus Miesbach',
    imageUrl: awo,
    link: 'https://awo-obb.de',
    mail: 'awo-obb-senioren.de',
    phone: '08025 - 70990'
  },
  {
    name: 'Ambulanter Pflegedienst Kerstin Saur',
    imageUrl: keinBild,  // No logo
    link: '',
    mail: 'pflegedienst.saur@t-online.de',
    phone: '08022 - 5552'
  },
  {
    name: 'Seniorenzentrum Der Schwaighof GmbH',
    imageUrl: schwaighof,
    link: 'https://der-schwaighof.de',
    mail: 'info@der-schwaighof.de',
    phone: '08022 - 6680'
  },
  {
    name: 'KWA Stift Rupertihof',
    imageUrl: rupertihof,
    link: 'https://kwa.de',
    mail: 'rupertihof@kwa.de',
    phone: '08022 - 2700'
  },
  {
    name: 'Vitanas Senioren Centrum Schlierachwinkl',
    imageUrl: keinBild,  // No logo
    link: 'https://vitanas.de',
    mail: 'C.Faehling@vitanas.de',
    phone: '08025 - 99220'
  },
  {
    name: 'Medical Park Bad Wiessee St. Hubertus',
    imageUrl: medicalpark,
    link: 'https://medicalpark.de',
    mail: 'st.hubertus@medicalpark.de',
    phone: '08022 - 8430'
  },
  {
    name: 'Bayerisches Rotes Kreuz Kreisverband Miesbach',
    imageUrl: brk,
    link: 'https://kvmiesbach.brk.de',
    mail: 'horst@kvmiesbach.brk.de',
    phone: '08025 - 282529'
  },
  {
    name: 'kbo-Lech-Mangfall-Klinik Agatharied',
    imageUrl: kbo,
    link: 'https://kbo.de',
    mail: 'Geschaeftsfuehrung-LMK@kbo.de',
    phone: '08026 - 3937000'
  },
  {
    name: 'Sophia mit P.S. gGmbH',
    imageUrl: keinBild,  // No logo
    link: 'https://sophia-suedbayern.de',
    mail: 'info@sophia-suedbayern.de',
    phone: '08024 - 902660'
  },
  {
    name: 'AWO-Seniorenzentrum Benediktbeuern',
    imageUrl: awo,
    link: 'https://awo-obb.de',
    mail: 'abt.altenhilfe@awo-obb.de',
    phone: '08857 - 89980'
  },
  {
    name: 'AWO-Seniorenzentrum Aying soziale Zukunft gGmbH',
    imageUrl: awo,
    link: 'https://awo-obb.de',
    mail: 'abt.altenhilfe@awo-obb.de',
    phone: '08095 87560'
  },
  {
    name: 'AWO-Seniorenzentrum Feldkirchen-Westerham',
    imageUrl: awo,
    link: 'https://awo-obb.de',
    mail: 'abt.altenhilfe@awo-obb.de',
    phone: '08063 20770'
  },
  {
    name: 'Heilpädagogischer Kindergarten der Lebenshilfe',
    imageUrl: keinBild,  // No logo
    link: 'https://lhmb.org',
    mail: 'info@lhmb.org',
    phone: '08026 8542'
  },
  {
    name: 'Medical Park Bad Feilnbach Betriebs GmbH & Co KG',
    imageUrl: medicalpark,
    link: 'https://medicalpark.de',
    mail: 'reithofpark@medicalpark.de',
    phone: '08066 180'
  },
  {
    name: 'Seniorenresidenz Wallberg',
    imageUrl: keinBild,  // No logo
    link: 'https://seniorenresidenz-wallberg.de',
    mail: 'info@seniorenresidenz-wallberg.de',
    phone: '08022 18730'
  },
  {
    name: 'Ambulanter Pflegedienst HumaCare GmbH',
    imageUrl: human_care,
    link: 'https://humacare.de',
    mail: 'info@humacare.de',
    phone: '08024 4780851'
  },
  {
    name: 'Berufsfachschule für Pflege GGSD Miesbach',
    imageUrl: ggsd,
    link: 'https://ggsd.de',
    mail: 'sz.miesbach@ggsd.de',
    phone: '08025 9000030'
  },
  {
    name: 'LaFe ambulanter Pflegedienst',
    imageUrl: keinBild,  // No logo
    link: '',
    mail: 'pflegelafe@gmail.com',
    phone: '08104/888 22 55'
  }
];


export default function verbundsMitgliederPage() {
  return (
    <FrontLayout>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl">Unsere Partner</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Wir kooperieren mit mehr als 20 Partnern, um eine umfangreiche Auswahl an Ausbildungsstätten anzubieten.
            </p>
          </div>
          <ul
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-2 md:grid-cols-3 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-5"
          >
            {people.map((person) => (
              <li key={person.name} className='group mb-6 hover:bg-teal-100/70 rounded-md p-5 hover:shadow-md hover:ring-1 hover:ring-teal-700/20'>

                <img className="mx-auto h-24 w-24 rounded-md" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900 h-20">{person.name}</h3>
                <div className='h-7 mt-6'>
                  <ul className="group-hover:flex hidden justify-center gap-x-6">
                    {person.link &&
                      <li>
                        <a href={person.link} target='_blank' rel="noreferrer" className="text-gray-400 hover:text-gray-300">
                          <span className="sr-only">Website</span>
                          <GlobeAltIcon className="mx-auto h-6 w-6 rounded full text-blue-400 hover:text-blue-600 cursor-pointer" />
                        </a>
                      </li>
                    }
                    {person.mail &&
                      <li>
                        <a href={'mailto:' + person.mail} className="text-gray-400 hover:text-gray-300">
                          <span className="sr-only">E-Mail</span>
                          <EnvelopeIcon className="mx-auto h-6 w-6 rounded full text-blue-400 hover:text-blue-600 cursor-pointer" />
                        </a>
                      </li>
                    }
                    {person.phone &&
                      <li>
                        <div onClick={() => copyToClipboard(person.phone || 'Keine Telefonnummer hinterlegt.')} className="text-gray-400 hover:text-gray-300">
                          <span className="sr-only">Telefonnummer</span>
                          <PhoneIcon className="mx-auto h-6 w-6 rounded full text-blue-400 hover:text-blue-600 cursor-pointer" />
                        </div>
                      </li>
                    }
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div >
    </FrontLayout >
  )

}