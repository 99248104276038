/* 
File: Switch
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\components
Created at: 2024-03-17 09:37:53
Author: rleon
*/

import React, { Component, useState } from 'react';
import { cn } from 'rizzui';
import { Switch } from '@headlessui/react'


export default function RSwitch({ value, label, onSwitch }: { value: boolean, label: string, onSwitch?: (value: boolean) => void }) {
  const [enabled, setEnabled] = useState(value)

  const _onSwitch = (checked: boolean) => {
    if (typeof onSwitch === 'function') {
      onSwitch(checked);
    }
    setEnabled(checked);
  }

  return (
    <Switch.Group as="div" className="block items-center">
      <Switch.Label as="div" className="text-sm">
        <span className="font-medium text-gray-700">{label}</span>
      </Switch.Label>
      <Switch
        checked={enabled}
        onChange={_onSwitch}
        className={cn(
          enabled ? 'bg-teal-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none mt-1'
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          className={cn(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        >
          <span
            className={cn(
              enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={cn(
              enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </Switch.Group>
  )

}