/* 
File: CalendarPage
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\pages\Calendar
Created at: 2024-03-01 21:48:28
Author: rleon
*/

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import i18next from 'i18next';
import { useModal } from '../../hooks/modal/use-modal';
import { useAuth } from '../../Providers/AuthContext';
import SelectCalendarAction from '../../views/SelectCalendarAction';
import CreatePracticalUse from '../../views/CreatePracticalUse';
import { useEffect, useState } from 'react';
import { rfetch } from '../../common/rutils';
import ViewEvent from '../../views/ViewEvent';
import Layout from '../../Layouts/Layout';
import moment from 'moment';
import toast from 'react-hot-toast';
import BookPracticalUse from '../../views/BookPracticalUse';


export default function CalendarPage() {
  const { t } = i18next;
  const { openModal } = useModal();
  const { user } = useAuth();
  const [events, setEvents] = useState<any[]>([]);
  const [uses, setUses] = useState<any[]>([]);

  useEffect(() => {
    customInit();
  }, [user]);

  const customInit = () => {
    if (user?.role === 'member' || user?.role === 'admin') {
      loadEvents();
      fetchData()
    } else if (user?.role === 'partner' && user.organization_name) {
      fetchData(user.organization_name)
    }
  }

  const loadEvents = () => {
    rfetch("/planing/events", JSON.stringify({}), (response) => {
      if (response.success) {
        setEvents(JSON.parse(response.data).map((event: any) => ({ id: event.id, start: event.start_date, end: event.end_date, title: event.name, message: event.description, color: '#0d9488' })));
      }
    });
  }

  const fetchData = (spec?: string) => {
    if (spec) {
      rfetch("/admin/practical_uses", JSON.stringify({ org: spec }), (response) => {
        if (response.success) {
          setUses(JSON.parse(response.data).map((use: any) => ({ id: use.id, start: use.start_date, end: use.end_date, title: `${use.org} (${use.free_seats - use.booked_count}/${use.free_seats})`, message: use.org, color: '#fb923c', use: use })));
        }
      })
    } else {
      rfetch("/admin/practical_uses", JSON.stringify({}), (response) => {
        if (response.success) {
          setUses(JSON.parse(response.data).map((use: any) => ({ id: use.id, start: use.start_date, end: use.end_date, title: `${use.org} (${use.free_seats - use.booked_count}/${use.free_seats})`, message: use.org, color: '#fb923c', use: use })));
        }
      })
    }

  };

  const handleBookedSeat = () => {
    customInit();
  }

  const calendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    firstDay: 1,
    locale: 'de',
    selectable: true,
    events: [...events, ...uses],
    buttonText: {
      today: t('label.today'),
      month: t('label.month'),
      week: t('label.week'),
      day: t('label.day'),
      list: t('label.list')
    },
    slotDuration: '00:30:00',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,dayGridDay'
    },
    dateClick: function (dateClickInfo: any) {

      const _start = dateClickInfo.date as Date;
      const _end = dateClickInfo.date as Date;
      _start.setHours(7, 0, 0);
      _end.setHours(8, 30, 0);

      if (user?.role !== 'partner') {
        openModal({ view: <SelectCalendarAction startDate={_start} endDate={_end} onUpdate={customInit} /> });
      } else {
        openModal({ view: <CreatePracticalUse startDate={_start} endDate={_end} onUpdate={customInit} /> });
      }
    },
    select: function (selectionInfo: any) {
      const _start = selectionInfo.start as Date;
      const _end = selectionInfo.end as Date;
      _start.setHours(7, 0, 0);
      _end.setHours(8, 30, 0);
      _end.setDate(_end.getDate() - 1);

      if (user?.role !== 'partner') {
        openModal({ view: <SelectCalendarAction startDate={_start} endDate={_end} onUpdate={customInit} /> });
      } else {
        openModal({ view: <CreatePracticalUse startDate={_start} endDate={_end} onUpdate={customInit} /> });
      }
    },
    eventClick: function (info: any) {
      if (user?.role === 'member' || user?.role === 'admin') {
        const isUse = info.event.extendedProps.use;

        if (isUse) {
          openModal({ view: <BookPracticalUse useId={isUse.id} onSubmit={handleBookedSeat} /> })
        } else {
          openModal({ view: <ViewEvent name={info.event.title} description={info.event.extendedProps.message} startDate={info.event.start} endDate={info.event.end} /> });
        }
      }

    }
  };

  return (
    <>
      <Layout>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <FullCalendar {...calendarOptions} />
        </div>
      </Layout>
    </>
  )
}