/* 
File: Logout
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\pages\Auth
Created at: 2024-02-29 18:54:34
Author: rleon
*/

import logo from '../../assets/img/logo-light-full.png'
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Providers/AuthContext';
import NoLayout from '../../Layouts/NoLayout';
import { useEffect } from 'react';


export default function Logout() {
    const { signOut } = useAuth();

    useEffect(() => {
        signOut();
    }, []);

    return (<></>)

}