/* 
File: impress
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\PA-LKMB\frontend\src\pages\Landing
Created at: 2024-04-10 14:17:37
Author: rleon
*/

import React from 'react';
import FrontLayout from '../../Layouts/FrontLayout';


export default function ImpressPage() {
  return (
    <FrontLayout>
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Impressum</h1>
          <div className="mt-10 max-w-2xl">
            <p>
              Gabriele Schmidl<br />
              St. Agatha-Str. 4<br />
              83734 Hausham
            </p>
            <p>
              Telefon: 08026/393-3190
            </p>
            <p>
              Telefax: 08026/393-3992
            </p>
            <p>
              E-Mail: gabriele.schmidl@khagatharied.de
            </p>
          </div>
        </div>
      </div>
    </FrontLayout>
  )

}