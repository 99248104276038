/* 
File: SelectCalendarAction
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\views
Created at: 2024-02-29 21:36:47
Author: rleon
*/

import { XMarkIcon } from '@heroicons/react/24/outline';
import { ActionIcon } from 'rizzui';
import { useModal } from '../hooks/modal/use-modal';
import i18next from 'i18next';
import { LuCalendarCheck2, LuCalendarClock } from 'react-icons/lu';
import CreateEvent from './CreateEvent';
import CreatePracticalUse from './CreatePracticalUse';


export default function SelectCalendarAction({ startDate, endDate, onUpdate }: { startDate?: Date, endDate?: Date, onUpdate?: () => void }) {
  const { closeModal, openModal } = useModal();

  const partnerActions = [
    {
      name: i18next.t("label.plan_event"),
      description:
        '',
      icon: LuCalendarClock,
      action: () => { openModal({ view: <CreateEvent startDate={startDate} endDate={endDate} onUpdate={onUpdate} /> }) }
    },
    {
      name: i18next.t("label.plan_practical_use"),
      description:
        '',
      icon: LuCalendarCheck2,
      action: () => { openModal({ view: <CreatePracticalUse startDate={startDate} endDate={endDate} onUpdate={onUpdate} /> }) }

    }
  ]

  return (
    <>
      <div className="m-auto space-y-1 p-4">
        <div className="pb-5">
          <div>
            <div className="mb-7 flex items-center justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-700">{i18next.t("label.choose_action")}</h2>
              <ActionIcon
                size="sm"
                variant="text"
                className='text-gray-600'
                onClick={() => closeModal()}
              >
                <XMarkIcon className="h-auto w-6" strokeWidth={1.8} />
              </ActionIcon>
            </div>
            <div className="text-base font-semibold leading-7 text-gray-700 mt-5">
              <div className="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-4xl">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                  {partnerActions.map((feature) => (
                    <div className="rounded-lg bg-slate-100 shadow hover:bg-gray-50 p-2 cursor-pointer" key={Math.random()} onClick={feature.action}>
                      <div className="px-4 py-5 sm:p-6">
                        <div className="relative pl-16">
                          <dt className="text-base font-semibold leading-7 text-gray-700">
                            <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-teal-600">
                              <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            {feature.name}
                          </dt>
                          <dd className="mt-2 text-sm leading-7 text-gray-600">{feature.description}</dd>
                        </div>
                      </div>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )

}