/* 
File: CreateApprenticeship
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\views
Created at: 2024-03-18 12:38:00
Author: rleon
*/

import { XMarkIcon } from '@heroicons/react/24/outline';
import { ActionIcon } from 'rizzui';
import { useModal } from '../hooks/modal/use-modal';
import i18next from 'i18next';
import { FormEvent, useState } from 'react';
import { rfetch } from '../common/rutils';
import moment from 'moment';
import RTextEditor from '../components/RTextEditor';

type Props = {
    id?: number;
    question?: string;
    answer?: string;
}

export default function CreateFAQ({ id, question, answer }: Props) {
    const { closeModal } = useModal();
    const [content, setContent] = useState<string>(answer || '');

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const title = (document.getElementById("faq-question") as HTMLInputElement).value;

        if (!id) {
            rfetch("/admin/create-faq", JSON.stringify({ title, content }), (response) => {
                if (response.success) {
                    closeModal();
                    window.location.reload();
                }
            })
        } else {
            rfetch("/admin/edit-faq", JSON.stringify({ id, title, content }), (response) => {
                if (response.success) {
                    closeModal();
                    window.location.reload();
                }
            })
        }

    }

    const handleChangeContent = (val: string) => {
        setContent(val);
    }

    return (
        <>
            <div className="m-auto space-y-1 p-4">
                <div className="pb-5">
                    <div>
                        <div className="mb-7 flex items-center justify-between">
                            <h2 className="text-base font-semibold leading-7 text-gray-700">{i18next.t("label.create_faq")}</h2>
                            <ActionIcon
                                size="sm"
                                variant="text"
                                className='text-gray-600'
                                onClick={() => closeModal()}
                            >
                                <XMarkIcon className="h-auto w-6" strokeWidth={1.8} />
                            </ActionIcon>
                        </div>
                        <div className="text-base font-semibold leading-7 text-gray-700 mt-5">
                            <form onSubmit={submit}>
                                <div className="">
                                    <div className="border-b border-gray-900/10 pb-12">
                                        <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                            <div className="sm:col-span-full">
                                                <label htmlFor="faq-question" className="block text-sm font-medium leading-6 text-gray-900">
                                                    {i18next.t("label.question")}
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="faq-question"
                                                        id="faq-question"
                                                        autoComplete="off"
                                                        placeholder={i18next.t("text.put_in_title")}
                                                        defaultValue={question || ''}
                                                        className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-full">
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    {i18next.t("label.answer")}
                                                </label>
                                                <div className="mt-2">
                                                    <RTextEditor value={content as string} placeholder='Gib ein Content ein.' onChange={handleChangeContent} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => closeModal()}>
                                        {i18next.t("label.cancel")}
                                    </button>
                                    <button
                                        type="submit"
                                        className="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                    >
                                        {i18next.t("label.save")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )

}