/* 
File: FrontLayout
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\Layouts
Created at: 2024-03-17 22:30:29
Author: rleon
*/

import React, { Component, useEffect } from 'react';
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import i18next from 'i18next';
import logo from '../assets/img/logo-light-full.png';
import logoDark from '../assets/img/logo-dark-full.png';
import { Link } from 'react-router-dom';
import { useAuth } from '../Providers/AuthContext';
import { getCurrentYear } from '../components/Footer';
import { FaHeart } from 'react-icons/fa';
import { rfetch } from '../common/rutils';

type apprenticeships = {
  name: string;
  href: string;
}

export default function FrontLayout({ children }: { children: React.ReactNode }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { user } = useAuth();
  const [apprenticeships, setApprenticeships] = useState<apprenticeships[]>([]);

  useEffect(() => {
    rfetch("/get-apprenticeships", JSON.stringify({}), (response) => {
      if (response.success) {
        const _D = JSON.parse(response.data);
        const list = _D.map((l: any) => ({ name: l.title, href: `apprenticeship?id=${l.id}` }));
        setApprenticeships(list);
      }
    })
  }, []);

  const navigation = [
    { name: 'Startseite', href: '/' },
    { name: i18next.t("label.verbunds_mitglieder"), href: '/verbunds_mitglieder' },
    { name: i18next.t("label.impress"), href: '/impress' },
  ]

  const createFooterNavigation = () => {
    const dashboardLinks = [];

    if (!user) {
      dashboardLinks.unshift({ name: i18next.t("label.login"), href: '/login' });
    } else {
      dashboardLinks.unshift({ name: i18next.t("label.logout"), href: '/logout' });
      dashboardLinks.unshift({ name: i18next.t("label.events"), href: '/app/calendar' });
      dashboardLinks.unshift({ name: i18next.t("label.dashboard"), href: '/app' });
    }

    return {
      dashboard: dashboardLinks,
      ausbildungen: apprenticeships,
      asubildungen2: [
        { name: 'Operationstechnischer Assistent', href: '#' },
        { name: 'Intensivpflegekraft', href: '#' },
      ],
      legal: [
        { name: i18next.t("label.privacy"), href: '/privacy' },
        { name: i18next.t("label.terms"), href: '/terms' },
      ]
    };
  };

  const footerNavigation = createFooterNavigation();

  return (
    <div className='bg-white w-full'>
      <header className="absolute inset-x-0 top-0 z-50 bg-teal-600/50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">PA LKMB</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {(user?.id) ? (
              <Link to="/app" className="text-sm font-semibold leading-6 text-white">
                {i18next.t("label.dashboard")} <span aria-hidden="true">&rarr;</span>
              </Link>
            ) : (
              <Link to="/login" className="text-sm font-semibold leading-6 text-white">
                {i18next.t("label.login")} <span aria-hidden="true">&rarr;</span>
              </Link>
            )}
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">PA LKMB</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="py-6">
                  {(user?.id) ? (
                    <Link
                      to="/app"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                    >
                      {i18next.t("label.dashboard")}
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                    >
                      {i18next.t("label.login")}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      {children}
      <footer className="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <img
                className="h-7"
                src={logoDark}
                alt="Company name"
              />
              <p className="text-sm leading-6 text-gray-600">
                {i18next.t("text.footer_slogan")}
              </p>
            </div>
            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.intern")}</h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.dashboard.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-700">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.apprenticeships")}</h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.ausbildungen.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-700">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                {/* <div>
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.apprenticeships_coop")}</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {footerNavigation.asubildungen2.map((item) => (
                                            <li key={item.name}>
                                                <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-700">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div> */}
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">{i18next.t("label.legal")}</h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 ">
            <p className="text-xs leading-5 text-gray-500 float-left">2023 - {getCurrentYear()} &copy; Ausbildungsverbund Landkreis Miesbach - Alle Rechte vorbehalten.</p>
            <p className="text-center text-xs leading-5 text-gray-500 float-right">
              Crafted with <FaHeart className="text-red-500 inline-block" /> by {' '}
              <a href="https://risingcore.eu/" target="_blank" className="text-blue-500 hover:text-blue-600" rel="noreferrer">Risingcore</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )

}