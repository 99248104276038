import i18next from "i18next"
import Layout from "../../Layouts/Layout"
import { useEffect, useMemo, useState } from "react"
import { rfetch } from "../../common/rutils";
import { User } from "../../Interfaces/User";
import moment from "moment";
import { BookmarkIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import RSwitch from "../../components/Switch";
import { useModal } from "../../hooks/modal/use-modal";
import BookPracticalUse from "../../views/BookPracticalUse";
import { Popover, cn } from "rizzui";


export default function AdminPagePracticalUses() {
    const { openModal } = useModal();
    const [uses, setUses] = useState<any[]>([]);
    const [sortConfig, setSortConfig] = useState({ key: 'start_date', direction: 'ascending' });
    const [showExpired, setShowExpired] = useState(false);
    const [showBookedUp, setBookedUp] = useState(false);
    const [startDate, setStartDate] = useState<any>(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState<any>(moment().add(7, 'day').format("YYYY-MM-DD"));

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        rfetch("/admin/practical_uses", JSON.stringify({}), (response) => {
            if (response.success) {
                setUses(JSON.parse(response.data));
            }
        })
    };

    const sortedAndFilteredUses = useMemo(() => {
        let items = [...uses].map(use => {
            let isFullyBooked = use.booked_count === use.free_seats;
            let isExpired = moment().isAfter(moment(use.end_date));

            return {
                ...use,
                status: isExpired ? 'abgelaufen' : isFullyBooked ? 'ausgebucht' : use.status
            };
        });

        if (!showExpired) {
            items = items.filter(use => use.status !== 'abgelaufen');
        }

        if (!showBookedUp) {
            items = items.filter(use => use.status !== 'ausgebucht');
        }

        if (startDate || endDate) {
            items = items.filter(use => {
                const useStartDate = moment(use.start_date);
                const useEndDate = moment(use.end_date);
                const afterStartDate = startDate ? useStartDate.isSameOrAfter(moment(startDate)) : true;
                const beforeEndDate = endDate ? useEndDate.isSameOrBefore(moment(endDate)) : true;
                return afterStartDate && beforeEndDate;
            });
        }

        if (sortConfig !== null) {
            items.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return items;
    }, [uses, sortConfig, showExpired, showBookedUp, startDate, endDate]);


    const requestSort = (key: any) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleBookedSeat = () => {
        fetchData();
    }

    const canBeBooked = (use: any) => {
        return use.status !== 'abgelaufen' && use.status !== 'ausgebucht';
    }

    const handleDeletePrUs = (use: any) => {
        rfetch("/admin/delete-practical-use", JSON.stringify({ id: use }), (response) => {
            if (response.success) {
                fetchData();
            }
        })
    }

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-700">{i18next.t("label.admin.practical_applications")}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {i18next.t("text.admin.practical_applications")}
                        </p>
                    </div>
                </div>
                <div className="mt-6 inline-flex gap-6">
                    <div>
                        <RSwitch label={i18next.t("label.show_expired")} value={showExpired} onSwitch={(v) => setShowExpired(v)} />
                    </div>
                    <div>
                        <RSwitch label={i18next.t("label.show_booked_up")} value={showBookedUp} onSwitch={(v) => setBookedUp(v)} />
                    </div>
                    <div>
                        <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
                            {i18next.t("label.start_date")}
                        </label>
                        <div className="mt-2">
                            <input
                                type="date"
                                name="start-date"
                                id="start-date"
                                autoComplete="off"
                                onChange={(e) => setStartDate(e.target.value)}
                                value={moment(startDate).format("YYYY-MM-DD")}
                                className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <p className="mt-2 text-sm text-blue-500 cursor-pointer hover:text-blue-700" onClick={() => setStartDate(null)}>
                            {i18next.t("label.reset")}
                        </p>
                    </div>
                    <div>
                        <label htmlFor="start-date" className="block text-sm font-medium text-gray-700">
                            {i18next.t("label.end_date")}
                        </label>
                        <div className="mt-2">
                            <input
                                type="date"
                                name="start-date"
                                id="start-date"
                                autoComplete="off"
                                onChange={(e) => setEndDate(e.target.value)}
                                value={moment(endDate).format("YYYY-MM-DD")}
                                className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <p className="mt-2 text-sm text-blue-500 cursor-pointer hover:text-blue-700" onClick={() => setEndDate(null)}>
                            {i18next.t("label.reset")}
                        </p>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0" onClick={() => requestSort('start_date')}>
                                            {i18next.t("label.start_date")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.end_date")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.free_seats")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.user_status")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.organization")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {sortedAndFilteredUses.map((use) => (
                                        <tr key={use.id}>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-700">{moment(use.start_date).format("DD.MM.YYYY HH:mm")}</div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-700">{moment(use.end_date).format("DD.MM.YYYY HH:mm")}</div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                <div className="text-gray-700">{use.free_seats - use.booked_count}/{use.free_seats}</div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                {use.status === 'abgelaufen' ? (
                                                    <span className="inline-flex items-center rounded-md bg-rose-50 px-2 py-1 text-xs font-medium text-rose-700 ring-1 ring-inset ring-rose-600/20">
                                                        {i18next.t("label.expired")}
                                                    </span>
                                                ) : (use.status === 'ausgebucht') ? (
                                                    <span className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                                        {i18next.t("label.booked_up")}
                                                    </span>
                                                ) : (
                                                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                        {i18next.t("label.registered")}
                                                    </span>
                                                )}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{use.org}</td>
                                            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                {canBeBooked(use) &&
                                                    <button
                                                        className='hover:text-green-600 text-gray-500 mx-2'
                                                        id='rt-book-seat'
                                                        onClick={() => openModal({ view: <BookPracticalUse useId={use.id} onSubmit={handleBookedSeat} /> })}
                                                    >
                                                        <BookmarkIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                    </button>
                                                }
                                                <button className="text-gray-500 hover:text-teal-600 mx-2" id="rt-edit">
                                                    <PencilSquareIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                </button>
                                                <Popover>
                                                    <Popover.Trigger>
                                                        <button className="text-gray-500 hover:text-red-600 mx-2" id="rt-delete">
                                                            <TrashIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                        </button>
                                                    </Popover.Trigger>
                                                    <Popover.Content className="z-50 bg-white shadow-lg">
                                                        {({ setOpen }) => (
                                                            <div className="w-56">
                                                                <h6 className="font-semibold">Praxiseinsatz löschen?</h6>
                                                                <p>Bist du sicher, dass Du diesen Eintrag löschen möchtest?</p>
                                                                <div className="flex justify-start gap-3 mb-1 mt-3">
                                                                    <button className="inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-[1.8px] focus-visible:ring-offset-2 ring-offset-background transition-colors duration-200 px-2.5 py-1 text-xs h-8 rounded-md bg-transparent border border-muted dark:backdrop-blur focus-visible:ring-muted hover:text-primary hover:border-black">
                                                                        Nein
                                                                    </button>
                                                                    <button className="inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-[1.8px] focus-visible:ring-offset-2 ring-offset-background transition-colors duration-200 px-2.5 py-1 text-xs h-8 rounded-md border border-transparent dark:backdrop-blur bg-gray-900 hover:bg-primary-dark dark:hover:bg-primary/90 focus-visible:ring-muted text-white"
                                                                        onClick={() => {
                                                                            handleDeletePrUs(use.id);
                                                                            setOpen(false);
                                                                        }}>
                                                                        Ja
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popover.Content>
                                                </Popover>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip
                anchorSelect="#rt-book-seat"
                content={i18next.t("label.book_seat")}
                delayShow={500}
            />
            <Tooltip
                anchorSelect="#rt-edit"
                content={i18next.t("label.edit")}
                delayShow={500}
            />
            <Tooltip
                anchorSelect="#rt-delete"
                content={i18next.t("label.delete")}
                delayShow={500}
            />
        </Layout>
    )
}
