/* 
File: BookPracticalUse
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\views
Created at: 2024-02-29 21:36:47
Author: rleon
*/

import { UserIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ActionIcon, cn } from 'rizzui';
import { useModal } from '../hooks/modal/use-modal';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { rfetch } from '../common/rutils';
import moment from 'moment';
import 'moment/locale/de';
import { PaperClipIcon } from '@heroicons/react/20/solid'
import toast from 'react-hot-toast';
import { useAuth } from '../Providers/AuthContext';


export default function BookPracticalUse({ useId, onSubmit }: { useId?: any, onSubmit?: () => void }) {
  const { closeModal } = useModal();
  const [use, setUse] = useState<any>();
  const [ausgewaehlteSitze, setAusgewaehlteSitze] = useState<any>([]);
  const { user } = useAuth();

  useEffect(() => {
    fetchData();
    // setInterval(() => {
    //   fetchData();
    // }, 2500);
    moment().locale('de');
  }, []);

  const fetchData = () => {
    rfetch("/admin/practical_use", JSON.stringify({ useId }), (response) => {
      if (response.success) {
        setUse(JSON.parse(response.data));
      }
    })
  }

  if (!use) {
    return (<></>);
  }

  if (!useId) {
    toast.error('No Id');
    return (<></>);
  }

  const istGebucht = (sitzIndex: any) => {
    return use.booked.some((buchung: any) => buchung.index === sitzIndex);
  };

  const toggleSitzAuswahl = (sitzIndex: any) => {
    // Verhindere die Auswahl, wenn der Sitz gebucht ist
    if (istGebucht(sitzIndex)) return;

    const istBereitsAusgewaehlt = ausgewaehlteSitze.includes(sitzIndex);
    if (istBereitsAusgewaehlt) {
      setAusgewaehlteSitze(ausgewaehlteSitze.filter((index: any) => index !== sitzIndex));
    } else {
      setAusgewaehlteSitze([...ausgewaehlteSitze, sitzIndex]);
    }
  };

  const _submit = () => {
    rfetch("/admin/book_practical_use", JSON.stringify({ useId, user: user?.id, seats: ausgewaehlteSitze }), (response) => {
      if (response.success) {
        closeModal();
        toast.success("Plätze gebucht.");
        if (typeof onSubmit === "function") {
          onSubmit();
        }
      }
    })
  }

  return (
    <>
      <div className="m-auto space-y-1 p-4 divide-y-2 divide-slate-300">
        <div className="pb-5">
          <div>
            <div className="mb-7 flex items-center justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-700">{i18next.t("label.book_practical_use")}</h2>
              <ActionIcon
                size="sm"
                variant="text"
                className='text-gray-600'
                onClick={() => closeModal()}
              >
                <XMarkIcon className="h-auto w-6" strokeWidth={1.8} />
              </ActionIcon>
            </div>
            <div className="text-base font-semibold leading-7 text-gray-700 mt-5">
              <div className="mt-6">
                <dl className="grid grid-cols-1 sm:grid-cols-2">
                  <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">{i18next.t("label.start_date")}</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{moment(use.start_date).format("DD.MM.YYYY")}</dd>
                    <dd className="text-xs font-normal text-gray-500">{moment(use.start_date).fromNow()}</dd>
                  </div>
                  <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">{i18next.t("label.end_date")}</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{moment(use.end_date).format("DD.MM.YYYY")}</dd>
                    <dd className="text-xs font-normal text-gray-500">{moment(use.end_date).fromNow()}</dd>
                  </div>
                </dl>
              </div>
              <div className='mt-3 mb-10'>
                <div className="px-4 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{i18next.t("label.note")}</dt>
                  <dd className="mt-1 text-sm text-gray-700 font-normal sm:mt-2">{use.other_message}</dd>
                </div>
              </div>
            </div>
          </div>
          <h2 className="text-sm font-medium text-gray-700 mt-2 mb-2">Freie Plätze</h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
            {(() => {
              let seatElemente = [];
              for (let i = 0; i < use.free_seats; i++) {
                // const el = use.free_seats[i];
                const istAusgewaehlt = ausgewaehlteSitze.includes(i);
                const gebucht = istGebucht(i);
                seatElemente.push(
                  <div
                    key={i}
                    className={cn(
                      gebucht ? 'border-red-500 bg-red-100' : istAusgewaehlt ? 'border-teal-500 bg-teal-100' : 'border-gray-300 bg-white',
                      !gebucht ? 'hover:border-gray-400' : '',
                      'flex justify-center items-center relative rounded-lg border px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2'
                    )}
                    onClick={() => !gebucht && toggleSitzAuswahl(i)}
                  >
                    <div className="flex-grow-0 flex items-center justify-center">
                      <span aria-hidden="true">
                        <UserIcon className='w-6 h-6' />
                      </span>
                    </div>
                  </div>
                );
              }
              return seatElemente;
            })()}
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6 border-t border-gray-500/20 space-y-3">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => closeModal()}>
              {i18next.t("label.cancel")}
            </button>
            <button
              type="submit"
              onClick={_submit}
              className="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              {i18next.t("label.book")}
            </button>
          </div>
        </div>
      </div >
    </>
  )

}