/* 
File: CreateUser
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\views
Created at: 2024-02-29 21:36:47
Author: rleon
*/

import { XMarkIcon } from '@heroicons/react/24/outline';
import { ActionIcon } from 'rizzui';
import { useModal } from '../hooks/modal/use-modal';
import i18next from 'i18next';
import { FormEvent, useEffect, useState } from 'react';
import { rfetch } from '../common/rutils';
import moment from 'moment-timezone';
import { useAuth } from '../Providers/AuthContext';
import toast from 'react-hot-toast';

export default function CreatePracticalUse({ startDate: propStartDate, endDate: propEndDate, onUpdate }: { startDate?: Date, endDate?: Date, onUpdate?: () => void }) {
  const { closeModal } = useModal();
  const { user } = useAuth();
  const [isExpired, setIsExpired] = useState(false);
  // const [_startDate, setStartDate] = useState(startDate || new Date());
  // const [_endDate, setEndDate] = useState(endDate || new Date());

  const getAdjustedDate = (date: Date, hours: number, minutes: number) => {
    const dateInBerlin = moment.tz(date, "Europe/Berlin");
    const adjustedDate = new Date(dateInBerlin.toString());
    adjustedDate.setHours(hours, minutes, 0, 0);
    return adjustedDate;
  };

  const [startDate, setStartDate] = useState(() => getAdjustedDate(propStartDate || new Date(), 7, 30));
  const [endDate, setEndDate] = useState(() => getAdjustedDate(propEndDate || new Date(), 16, 30));

  useEffect(() => {
    // Aktualisiere die States, wenn die Props ändern. Dies könnte zu einem unerwünschten Verhalten führen,
    // wenn du nicht möchtest, dass Änderungen an den Props die States überschreiben, nachdem der Nutzer sie möglicherweise angepasst hat.
    if (propStartDate) {
      setStartDate(getAdjustedDate(propStartDate, 7, 30));
    }
    if (propEndDate) {
      setEndDate(getAdjustedDate(propEndDate, 16, 30));
    }
  }, [propStartDate, propEndDate]);

  // useEffect(() => {

  //   let isExpired = moment().isAfter(moment(startDate));
  //   if (isExpired) {
  //     closeModal();
  //     toast.error("Planung liegt hinter heutigem Datum und kann deshalb nicht gebucht werden.");
  //   }

  // }, []);

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const freeSeats = (document.getElementById('free_seats') as HTMLInputElement).value;
    const otherMessages = (document.getElementById('other-messages') as HTMLTextAreaElement).value;

    let isExpired = moment().isAfter(moment(startDate));
    if (isExpired) {
      closeModal();
      return toast.error("Planung liegt hinter heutigem Datum und kann deshalb nicht gebucht werden.");
    }

    console.log(startDate)
    console.log(endDate)

    rfetch("/planing/practicaluse", JSON.stringify({ startDate, endDate, freeSeats, otherMessages, org: user?.organization_name }), (response) => {
      if (response.success) {
        if (typeof onUpdate === "function") {
          onUpdate();
        }
        closeModal();
      }
    })
  }

  return (
    <>
      <div className="m-auto space-y-1 p-4">
        <div className="pb-5">
          <div>
            <div className="mb-7 flex items-center justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-700">{i18next.t("label.plan_practical_use")}</h2>
              <ActionIcon
                size="sm"
                variant="text"
                className='text-gray-600'
                onClick={() => closeModal()}
              >
                <XMarkIcon className="h-auto w-6" strokeWidth={1.8} />
              </ActionIcon>
            </div>
            <div className="text-base font-semibold leading-7 text-gray-700 mt-5">
              <form onSubmit={submit}>
                <div className="">
                  <div className="border-b border-gray-900/10 pb-12">
                    {/* <h2 className="text-base font-semibold leading-7 text-gray-900">{i18next.t("label.personal_information")}</h2> */}
                    <p className="mt-1 text-sm leading-6 text-gray-500 font-normal">{i18next.t("text.plan_practical_use_information")}</p>
                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                      <div className="sm:col-span-3">
                        <label htmlFor="start-date" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.start_date")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="datetime-local"
                            name="start-date"
                            id="start-date"
                            autoComplete="off"
                            onChange={(e) => setStartDate(new Date(e.target.value))}
                            defaultValue={moment(startDate).format("YYYY-MM-DDTHH:mm")}
                            className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="end-date" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.end_date")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="datetime-local"
                            name="end-date"
                            id="end-date"
                            autoComplete="off"
                            onChange={(e) => setEndDate(new Date(e.target.value))}
                            defaultValue={moment(endDate).format("YYYY-MM-DDTHH:mm")}
                            className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="free_seats" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.free_seats")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="number"
                            name="free_seats"
                            id="free_seats"
                            autoComplete="off"
                            min={1}
                            max={99}
                            defaultValue={1}
                            className="block w-full rounded-md border-0 py-1.5 font-normal text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          />

                        </div>
                      </div>

                      {/* <div className="sm:col-span-3">
                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.contact_person")}
                        </label>
                        <div className="mt-2">
                          <RSelect values={people} />
                        </div>
                        <p className="mt-2 text-xs font-normal text-gray-500" id="email-description">
                          <Link to="#" className='font-bold hover:text-teal-600'>Ansprechpartner hinzufügen</Link>
                        </p>
                      </div> */}

                      <div className="sm:col-span-full">
                        <label htmlFor="event-description" className="block text-sm font-medium leading-6 text-gray-900">
                          {i18next.t("label.other")}
                        </label>
                        <div className="mt-2">
                          <textarea
                            rows={4}
                            id="other-messages"
                            name="other-messages"
                            autoComplete="off"
                            placeholder={i18next.t("text.put_in_other_message")}
                            className="resize-none block w-full rounded-md border-0 placeholder:font-normal py-1.5 font-normal placeholder:text-gray-400 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => closeModal()}>
                    {i18next.t("label.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    {i18next.t("label.save")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </>
  )

}