/* 
File: NotFound
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\pages\Errors
Created at: 2024-02-27 13:46:46
Author: rleon
*/

import i18next from 'i18next';
import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';


export default function NotFound() {
  const { t } = i18next;
  const navigate = useNavigate();

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-teal-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("label.page_not_found")}</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">{t("text.page_not_found")}</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            onClick={() => navigate(-1)}
            className="rounded-md bg-teal-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            {t("label.back")}
          </Link>
        </div>
      </div>
    </main>
  )

}