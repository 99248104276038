import i18next from "i18next"
import Layout from "../../Layouts/Layout"
import { useEffect, useState } from "react"
import { rfetch } from "../../common/rutils";
import { User } from "../../Interfaces/User";
import { useModal } from "../../hooks/modal/use-modal";
import CreateUser from "../../views/CreateUser";
import moment from "moment";


export default function AdminPageUsers() {
    const [users, setUsers] = useState<User[]>([]);
    const { openModal } = useModal();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        rfetch("/admin/users", JSON.stringify({}), (response) => {
            if (response.success) {
                setUsers(JSON.parse(response.data));
            }
        })
    }


    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-700">{i18next.t("label.admin.users")}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {i18next.t("text.list_all_users")}
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => openModal({ view: <CreateUser /> })}
                            className="block rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                        >
                            {i18next.t("label.add_user")}
                        </button>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-0">
                                            {i18next.t("label.name")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 max-w-32">
                                            {i18next.t("label.organization")}
                                        </th>
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.user_status")}
                                        </th> */}
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.user_type")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.registered_at")}
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700">
                                            {i18next.t("label.updated_at")}
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                            <span className="sr-only">{i18next.t("label.edit")}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {users.map((person) => (
                                        <tr key={person.email}>
                                            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                <div className="flex items-center">
                                                    <div className="h-11 w-11 flex-shrink-0">
                                                        <img className="h-11 w-11 rounded-full" src={person.profile_picture || ""} alt="" />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="font-medium text-gray-900">{person.firstname} {person.lastname}</div>
                                                        <div className="mt-1 text-gray-500">{person.email}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 max-w-32">
                                                <div className="text-gray-900 truncate">{person.organization_name}</div>
                                            </td>
                                            {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                {person.status ? (
                                                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                        {i18next.t("label.active")}
                                                    </span>
                                                ) : (
                                                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                                        {i18next.t("label.inactive")}
                                                    </span>
                                                )}

                                            </td> */}

                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{i18next.t("label." + person.role)}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{moment(person.registered_at).format('lll')}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{moment(person.updated_at).format('lll')}</td>
                                            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                <a
                                                    href="#"
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                    onClick={() => openModal({ view: <CreateUser user={person} onUpdate={fetchData} /> })}
                                                >
                                                    {i18next.t("label.edit")}<span className="sr-only">, {person.username}</span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    )
}
