import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import FrontLayout from '../../Layouts/FrontLayout'
import { useLocation } from 'react-router-dom';
import { Fragment, useEffect, useRef, useState } from 'react';
import { rfetch } from '../../common/rutils';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import { Loader } from 'rizzui';

export default function LandingApprenticeships() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [content, setContent] = useState<any>(null);
    const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)

    useEffect(() => {
        rfetch('/apprenticesips', JSON.stringify({ id }), (response) => {
            if (response.success) {
                setContent(JSON.parse(response.data));
            }
        });
    })

    if (!content) {
        return (
            <FrontLayout>
                <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <Loader className='h-8 w-8 mx-auto mb-10' aria-aria-hidden={true} />
                        <h2 className="text-2xl font-bold tracking-tight text-gray-700 sm:text-2xl">Die Seite wird geladen...</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Dauert es länger als erwartet? Dann gibt es diese Seite vielleicht nicht oder Du wurdest fehlerhaft weitergeleitet.
                        </p>
                    </div>
                </div>
            </FrontLayout>
        )
    }

    return (
        <FrontLayout>
            <div className="bg-white px-6 py-32 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    {/* <p className="text-base font-semibold leading-7 text-indigo-600">{id}</p> */}
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{content.title}</h1>
                    <div className="mt-10 max-w-2xl">
                        <p dangerouslySetInnerHTML={{ __html: content.html }}>
                        </p>
                    </div>
                </div>
            </div>
        </FrontLayout>
    )
}
