/* 
File: i18n
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src
Created at: 2024-02-27 13:28:54
Author: rleon
*/

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import de from '../src/locales/de/translations.json';

const resources = {
  de: {
    translation: de,
  },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpBackend)
  .init({
    resources,
    lng: 'de',
    fallbackLng: 'de',
    debug: true,
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

