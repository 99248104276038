/* 
File: routes.config
Path: c:\Users\rleon\Documents\RDS-Projects\Ausbildungsverbund LKMB\Homepage\frontend\src\config
Created at: 2024-02-27 13:25:24
Author: rleon
*/

import { FC } from 'react';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/Errors/NotFound';
import SignIn from '../pages/Auth/SignIn';
import CalendarPage from '../pages/Calendar/CalendarPage';
import Logout from '../pages/Auth/Logout';
import { config } from './default.config';
import LandingPage from '../pages/Landing';
import AdminPageUsers from '../pages/admin/users';
import AdminPagePracticalUses from '../pages/admin/practicalUses';
import PrivacyPage from '../pages/Landing/privacy';
import verbundsMitgliederPage from '../pages/Landing/verbundsMitglieder';
import FileManagerPage from '../pages/fielmanager';
import AdminPageApprenticeships from '../pages/admin/apprenticeships';
import LandingApprenticeships from '../pages/Landing/apprenticeships';
import AdminPageFAQs from '../pages/admin/faqs';
import ImpressPage from '../pages/Landing/impress';
// Definiere die Route Interface
interface Route {
  path: string;
  component: FC;
  protected: boolean;
}

// Definiere deine öffentlichen Routen
export const publicRoutes: Route[] = [
  {
    path: '/',
    component: LandingPage,
    protected: false,
  },
  {
    path: '/login',
    component: SignIn,
    protected: false,
  },
  {
    path: '/logout',
    component: Logout,
    protected: false,
  },
  {
    path: '/login',
    component: SignIn,
    protected: false,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
    protected: false,
  },
  {
    path: '/impress',
    component: ImpressPage,
    protected: false,
  },
  {
    path: '/verbunds_mitglieder',
    component: verbundsMitgliederPage,
    protected: false,
  },
  {
    path: '/apprenticeship',
    component: LandingApprenticeships,
    protected: false,
  },
];

// Definiere deine geschützten Routen
export const protectedRoutes: Route[] = [
  {
    path: config.appPath + '/dashboard',
    component: Dashboard,
    protected: true,
  },
  {
    path: config.appPath,
    component: Dashboard,
    protected: true,
  },
  {
    path: config.appPath + '/calendar',
    component: CalendarPage,
    protected: true,
  },
  {
    path: config.appPath + '/calendar',
    component: CalendarPage,
    protected: true,
  },
  {
    path: config.appPath + '/admin/users',
    component: AdminPageUsers,
    protected: true,
  },
  {
    path: config.appPath + '/admin/practical_applications',
    component: AdminPagePracticalUses,
    protected: true,
  },
  {
    path: config.appPath + '/admin/apprenticeships',
    component: AdminPageApprenticeships,
    protected: true,
  },
  {
    path: config.appPath + '/admin/faqs',
    component: AdminPageFAQs,
    protected: true,
  },
  {
    path: config.appPath + '/filemanager',
    component: FileManagerPage,
    protected: true,
  },
];

export const notFoundRoute: Route = {
  path: '*',
  component: NotFound,
  protected: false,
};
