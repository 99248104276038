import { cn } from "rizzui";
import Layout from "../../Layouts/Layout";
import { ArrowDownTrayIcon, ArrowUpOnSquareStackIcon, BookmarkIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { cfetch, formDatafetch, formatBytes, rfetch } from "../../common/rutils";
import moment from "moment";
import { useAuth } from "../../Providers/AuthContext";

export default function FileManagerPage() {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<any[]>([]);
    const { user } = useAuth();

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        rfetch("/admin/files", null, (response) => {
            if (response.success) {
                console.log(response)
                setFiles(response.data);
            }
        }, "GET");
    }

    const people = [
        { name: 'Text Dokument.txt', size: 1, owner: 'info@risingcore.eu' },
        // More people...
    ]

    const handleOpenUpload = () => {
        if (fileInputRef)
            fileInputRef.current?.click();
    }

    const handleFileSelected = async (event: any) => {
        const files = event.target.files;

        var fd = new FormData();
        fd.append("myFile", files[0]);
        fd.append("userId", user?.id.toString() || 'none');

        var res = await formDatafetch("/upload", fd)
        if (res.success) {
            loadData();
        }

    };

    const handleDeleteFile = (id: any) => {
        rfetch("/admin/file/delete", JSON.stringify({ id }), (response) => {
            if (response.success) {
                loadData();
            }
        })
    }

    const requestURL = process.env.REACT_APP_API_URL;

    return (
        <Layout>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Dateien</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Übersicht Ihrer Dateien mit Optionen zum Hochladen, Herunterladen und Teilen. Zeigt Dateiname, Größe und Datum.
                        </p>
                    </div>

                </div>
                <div className="mt-6">
                    <input
                        type="file"
                        style={{ display: 'none' }} // Verstecke das Input-Element
                        ref={fileInputRef}
                        onChange={handleFileSelected}
                    // multiple
                    />
                    <button
                        type="button"
                        onClick={handleOpenUpload}
                        className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 "
                    >
                        <ArrowUpOnSquareStackIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <span className="mt-2 block text-sm font-semibold text-gray-700">Datei hochladen</span>
                    </button>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300  py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  backdrop-filter sm:pl-6 lg:pl-8"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300  px-3 py-3.5 text-left text-sm font-semibold text-gray-900  backdrop-filter sm:table-cell"
                                        >
                                            Größe
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300  px-3 py-3.5 text-left text-sm font-semibold text-gray-900  backdrop-filter lg:table-cell"
                                        >
                                            Besitzer
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300  px-3 py-3.5 text-left text-sm font-semibold text-gray-900  backdrop-filter lg:table-cell"
                                        >
                                            Hochgeladen am
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300  py-3.5 pl-3 pr-4  backdrop-filter sm:pr-6 lg:pr-8"
                                        >
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {files.map((file, fileIdx) => (
                                        <tr key={file.id}>
                                            <td
                                                className={cn(
                                                    fileIdx !== files.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                                )}
                                            >
                                                {file.original_name}
                                            </td>
                                            <td
                                                className={cn(
                                                    fileIdx !== files.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell'
                                                )}
                                            >
                                                {formatBytes(file.size)}
                                            </td>
                                            <td
                                                className={cn(
                                                    fileIdx !== files.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                                                )}
                                            >
                                                {file.username}
                                            </td>
                                            <td
                                                className={cn(
                                                    fileIdx !== files.length - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                                                )}
                                            >
                                                {moment(file.uploaded_at).format("lll")}
                                            </td>
                                            <td
                                                className={cn(
                                                    fileIdx !== files.length - 1 ? 'border-b border-gray-200' : '',
                                                    'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                                                )}
                                            >
                                                <a
                                                    href={requestURL + "/admin/file/" + file.id + "/download"}
                                                    className="text-gray-500 hover:text-teal-600 mx-2"
                                                    id="f-download"
                                                // target="_blank"
                                                >
                                                    <ArrowDownTrayIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                </a>
                                                {/* <button className="text-gray-500 hover:text-teal-600 mx-2" id="f-rename">
                                                    <PencilSquareIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                </button> */}
                                                <button
                                                    className="text-gray-500 hover:text-red-600 mx-2"
                                                    id="rt-delete"
                                                    onClick={() => handleDeleteFile(file.id)}
                                                >
                                                    <TrashIcon className="h-h w-6 inline-block" aria-hidden={true} />
                                                </button>
                                                {/* <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                    Edit<span className="sr-only">, {person.name}</span>
                                                </a> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Tooltip
                anchorSelect="#f-download"
                content={i18next.t("label.download")}
                delayShow={500}
                className="z-50"
            />
            <Tooltip
                anchorSelect="#f-rename"
                content={i18next.t("label.rename")}
                delayShow={500}
                className="z-50"
            />
            <Tooltip
                anchorSelect="#rt-delete"
                content={i18next.t("label.delete")}
                delayShow={500}
                className="z-50"
            />
        </Layout>
    )
}