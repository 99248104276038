import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { notFoundRoute, protectedRoutes, publicRoutes } from './config/routes.config';
import './i18n';
import { AuthProvider } from "./Providers/AuthContext";
import { Toaster } from "react-hot-toast";
import GlobalModal from "./hooks/modal/container";

// Importiere deine Seiten und Komponenten

const isAuthenticated = () => {
  // Logik zur Überprüfung der Authentifizierung
  return true; // oder false, je nach Authentifizierungsstatus
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
          {protectedRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={isAuthenticated() ? <route.component /> : <Navigate to="/login" />}
            />
          ))}
          <Route path={notFoundRoute.path} element={<notFoundRoute.component />} />
        </Routes>
        <Toaster />
        <GlobalModal />
      </AuthProvider>
    </Router>
  );
}

export default App;
